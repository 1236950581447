import React, { Component } from 'react';
import { Image,Col,Row ,Dropdown} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { classList } from "@utils";
import { addTask } from '../../redux/actions/TaskActions';
import { connect } from 'react-redux';
import { isTrueUploadedFileTrue } from '../../core/workingWithFile';
import SuccessfulAlert from '../notificationComponents/SuccessfulAlert';
import Loading from '../notificationComponents/Loading';
import filePng from '../../files/images/icons/file.png';
import '../../../styles/app/components/taskForm.css';

let imagesBase64 = [];
export class TaskForm extends Component {
 
  state = {
    applicationId: "",
    applicationName: "",
    taskTypeId: "",
    typeName: "",
    taskPriorityId: "",
    priorityName: "",
    shortDescription: "",
    longDescription: "",
    files: [],
    file: "",
    filesBase64: [],
    isImage: "data:image"
  };

  handleSubmit = (values) => {
    this.state.shortDescription = values.shortDescription;
    this.state.longDescription = values.longDescription;
    this.props.addTask(this.state);
  };

  handeOnChangeFile = (e) => {

    for(let i = 0; i < e.target.files.length; i++){

        const reader = new FileReader();

        reader.onloadend = () => {
         if(!isTrueUploadedFileTrue(reader.result)){
           document.getElementById("unsupportedFileTypeError").innerText = "File tipi dəstəklənmir!";
         }
        else{
            imagesBase64.push(reader.result)
            this.setState({
              files: imagesBase64
            });
            document.getElementById("unsupportedFileTypeError").innerText = "";
          }
        };
        reader.readAsDataURL(e.target.files[i]);
    }
  };


  handleAppDropDown = (e)=>{
    this.setState({
        applicationId : parseInt(e),
        applicationName: this.props.companyApplicationDtos.filter((x) => x.id == e)[0].applicationName
    });
  }

  handleTypeDropDown = (e)=>{
    this.setState({
        taskTypeId : parseInt(e),
        typeName: this.props.taskTypeDtos.filter((x) => x.id == e)[0].type
    })
  }

  handlePriorityDropDown = (e)=>{
    this.setState({
        taskPriorityId : parseInt(e),
        priorityName: this.props.taskPriorityDtos.filter((x) => x.id == e)[0].priorityLevel
    });
  }

  clickFileBtn = () => {
    var file = document.getElementById("file");
    file.click();
  };

  removeFile = (image) => {
    var updatedFiles = this.state.files.filter((x) => x != image);
    this.setState({
      files: updatedFiles,
    });
    imagesBase64 = updatedFiles;
    var inputFile = document.getElementById("file");
    inputFile.value="";
  };

  render() {
    let { companyApplicationDtos,taskPriorityDtos,taskTypeDtos,fetching } = this.props;
    let { taskIsSuccessfulyAdded,taskAddFetching } = this.props.TaskReducer;
    return (
      <div style={{ marginLeft: "18%" }}>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body" style={{opacity:fetching != false ? .5 : 1}}>
                <div className="card-title" style={{ textAlign: "center" }}>
                  Texniki Tapşırıq
                </div>
                {
                  taskAddFetching && <Loading styleLoading={"left:45%"}/>
                }
                {
                  taskIsSuccessfulyAdded && <SuccessfulAlert message="İstifadəçinin qeydiyyatı uğurla tamamlandı" title="Uğurlu" redirectRoute="/TaskManagement/new_tasks"/>
                }
                <Formik initialValues={this.state} validationSchema={basicFormSchema} onSubmit={this.handleSubmit}>
                  {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting,}) => {
                    return (
                      <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className={classList({
                            "col-md-12 mb-3": true,
                            "valid-field":
                                touched.applicationId &&
                                !errors.applicationId,
                            "invalid-field":
                                touched.applicationId &&
                                errors.applicationId,
                            })}>
                                {["outline-primary"].map((variant, i) => (
                                <Dropdown key={i} onSelect={this.handleAppDropDown}>
                                    <div hidden>{this.state.applicationId !== "" ? values.applicationId = this.state.applicationId : null}</div>
                                    <Dropdown.Toggle variant={variant} className="mr-3 mb-3 col-md-6" disabled={fetching}>{ this.state.applicationName !== "" ? this.state.applicationName : "Tətbiq" }</Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        {
                                          companyApplicationDtos
                                          &&
                                          companyApplicationDtos.map(company =>
                                            <Dropdown.Item eventKey={company.id} key={Math.random(0,45)}>{company.applicationName}</Dropdown.Item>
                                          )
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                ))}
                                <div className="invalid-tooltip" style={{top:"65%"}}>Sahə tələbolunandır</div>
                            </div>
                            <div className={classList({
                            "col-md-12 mb-3": true,
                            "valid-field":
                                touched.taskTypeId &&
                                !errors.taskTypeId,
                            "invalid-field":
                                touched.taskTypeId &&
                                errors.taskTypeId,
                            })}>
                                {["outline-primary"].map((variant, i) => (
                                <Dropdown key={i} onSelect={this.handleTypeDropDown}>
                                    <div hidden>{this.state.taskTypeId !== "" ? values.taskTypeId = this.state.taskTypeId : null}</div>
                                    <Dropdown.Toggle variant={variant} className="mr-3 mb-3 col-md-6" disabled={fetching}>{ this.state.typeName !== "" ? this.state.typeName : "Tip" }</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                          taskTypeDtos
                                          &&
                                          taskTypeDtos.map(type=>
                                            <Dropdown.Item eventKey={type.id} key={Math.random(0,45)} >{type.type}</Dropdown.Item>
                                          )
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                ))}
                                <div className="invalid-tooltip" style={{top:"65%"}}>Sahə tələbolunandır</div>
                            </div>
                            <div className={classList({
                            "col-md-12 mb-3": true,
                            "valid-field":
                                touched.taskPriorityId &&
                                !errors.taskPriorityId,
                            "invalid-field":
                                touched.taskPriorityId &&
                                errors.taskPriorityId,
                            })}>
                                {["outline-primary"].map((variant, i) => (
                                <Dropdown key={i} onSelect={this.handlePriorityDropDown}>
                                    <div hidden>{this.state.taskPriorityId !== "" ? values.taskPriorityId = this.state.taskPriorityId : null}</div>
                                    <Dropdown.Toggle variant={variant} className="mr-3 mb-3 col-md-6" disabled={fetching}>{ this.state.priorityName !== "" ? this.state.priorityName : "Prioritet" }</Dropdown.Toggle>
                                    <Dropdown.Menu style={{width:'45%'}}>
                                        {
                                          taskPriorityDtos
                                          &&
                                          taskPriorityDtos.map(priority=>
                                            <Dropdown.Item eventKey={priority.id} key={Math.random(0,45)}>{priority.priorityLevel}</Dropdown.Item>
                                          )
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                ))}
                                <div className="invalid-tooltip" style={{top:"65%"}}>Sahə tələbolunandır</div>
                            </div>
                        <div
                            className={classList({
                            "col-md-12 mb-3": true,
                            "valid-field":
                                touched.shortDescription &&
                                !errors.shortDescription,
                            "invalid-field":
                                touched.shortDescription &&
                                errors.shortDescription,
                            })}
                        >
                            <label>
                            Qısa Başlıq
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="validationCustom203"
                                placeholder="Qısa Başlıq"
                                name="shortDescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                values={values.shortDescription}
                                disabled={fetching}
                            />
                            <div className="invalid-tooltip">
                            Sahə tələbolunandır
                            </div>
                        </div>
                    </div>
                        <div className="form-row">
                          <div
                            className={classList({
                              "col-md-12 mb-3": true,
                              "valid-field": touched.longDescription && !errors.longDescription,
                              "invalid-field":
                                touched.longDescription && errors.longDescription,
                            })} style={{marginTop:"1%"}}
                          >
                            <label htmlFor="validationCustom04">Haqqında</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              placeholder="Haqqında"
                              name="longDescription"
                              value={values.longDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={8}
                              disabled={fetching}
                            />
                            <div className="invalid-tooltip">
                              Sahə tələbolunandır
                            </div>
                          </div>
                        </div>
                        <div className="input-group mb-3" style={{marginTop:'1.2%'}}>
                          <input
                            className="btn btn-danger"
                            id="btnFile"
                            onClick={this.clickFileBtn}
                            value="File Əlavə Et"
                            readOnly={true}
                            disabled={fetching}
                          />
                          <input
                            type="file"
                            className="form-control"
                            name="file"
                            onChange={this.handeOnChangeFile}
                            style={{ display: "none" }}
                            id="file"
                            multiple
                          />
                        </div>
                        <div className={classList({ "col-md-12": true })}>
                        <span style={{fontWeight:"bold",color:"red",fontSize:"22px"}} id="unsupportedFileTypeError"></span>
                          <Row>
                            {this.state.files !== undefined
                              ? 
                              this.state.files.map((image) => (
                                  <Col md="4" key={Math.random(0,10000)}>
                                    <div
                                      style={{ marginTop: "1%" }}
                                      key={Math.random}
                                    >
                                    <Image
                                        src={image.includes(this.state.isImage) != false ? image : filePng}
                                        thumbnail
                                        style={{
                                          width: "80%",
                                          height: "18vh",
                                        }}
                                      />
                                      
                                      <i
                                        className="i-Close-Window"
                                        style={{
                                          fontSize: "24px",
                                          position: "absolute",
                                        }}
                                        onClick={() => this.removeFile(image)}
                                      ></i>
                                    </div>
                                  </Col>
                                ))
                              : 
                              null}
                          </Row>
                        </div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{ marginTop: "1%" }}
                          disabled={fetching}
                        >
                          Göndər
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const basicFormSchema = yup.object().shape({
    applicationId: yup.string().required("Required"),
    taskTypeId: yup.string().required("Required"),
    taskPriorityId: yup.string().required("Required"),
    shortDescription: yup.string().required("Required"),
    longDescription: yup.string().required("Required")
});

const mapStateToProps = ({ TaskReducer }) => {
  return{
    TaskReducer
  }
}

export default connect(mapStateToProps,{ addTask })(TaskForm);
