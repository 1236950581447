import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { sendRestorePasswordCode } from '../redux/actions/UserActions';
import { Link } from "react-router-dom";
import Loading from '../components/notificationComponents/Loading';
import SuccessfulAlert from '../components/notificationComponents/SuccessfulAlert';
import apertechlogo from '../files/images/template/apertechlogo.png';

const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Email tələbolunandır")
});

class ForgotPassword extends Component {
  state = {
    email: "",
    isEmailSended: false,
    userEmailSendFetching: false,
    errorMessage: ""
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (value) => {
    this.props.sendRestorePasswordCode(value.email);
  };

  componentWillReceiveProps(nextProps){
    this.setState
    ({ 
      isEmailSended: nextProps.UserReducer.isEmailSended,
      userEmailSendFetching: nextProps.UserReducer.userEmailSendFetching,
      errorMessage: nextProps.UserReducer.error
    })
    if(nextProps.UserReducer.restorePasswordError !== undefined || nextProps.UserReducer.restorePasswordError !== "" || nextProps.UserReducer.restorePasswordError !== null){
      document.getElementById("errorLabel").innerText = nextProps.UserReducer.restorePasswordError;
    }
  }

  render() {
    let { userEmailSendFetching,isEmailSended } = this.state;
    return (
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: "url('https://www.asimot.com/wp-content/uploads/2018/09/utilities-2000x800.jpg')"
        }}
      >
        {
          userEmailSendFetching === true ? <Loading styleLoading={"left:45%"}/> : null
        }
        {
          isEmailSended === true 
          ? 
          <SuccessfulAlert title={"Zəhmət olmasa email ünvanına daxil olaraq şifrə yenilənməsinə keçid edin."} redirectRoute={"/"}/> 
          : 
          null
        }
        <div className="auth-content">
          <div className="card o-hidden" style={{opacity:userEmailSendFetching !== false ? .5 : 1}}>
            <div className="row">
              <div className="col-md-12">
                <div className="p-4">
                  <div className="auth-logo text-center mb-4">
                    <img src={apertechlogo} alt="" style={{width:"113px",height:"94px"}}/>
                  </div>
                  <Formik
                    initialValues={this.state}
                    validationSchema={ForgotPasswordSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div style={{textAlign:"center",color:"red"}}><label id="errorLabel"></label></div>
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                          <input
                            className="form-control form-control-rounded position-relative"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            disabled={userEmailSendFetching}
                          />
                          {errors.email && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <button
                          className="btn btn-rounded btn-primary btn-block mt-2"
                          type="submit"
                          disabled={userEmailSendFetching}
                        >
                          Təsdiqlə
                        </button>
                      </form>
                    )}
                  </Formik>

                  <div className="mt-3 text-center">
                    <Link to="/" className="text-muted">
                      <u>Əsas Səhifə</u>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ UserReducer }) =>{
  return{
    UserReducer
  }
}

const mapDispatchToProps = {
  sendRestorePasswordCode
}

export default connect(mapStateToProps,mapDispatchToProps)(ForgotPassword);

