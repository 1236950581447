import React, { Component } from "react";

class Error extends Component {
  state = {};
  render() {
    return (
      <div className="not-found-wrap text-center">
        <h1 className="text-60">404</h1>
        <p className="text-36 subheading mb-3">Uğursuz Əməliyyat!</p>
        <p className="mb-5  text-muted text-18">
          Zəhmət olmasa administratora müraciət edin.
        </p>
      </div>
    );
  }
}

export default Error;
