import React, { Component,Fragment } from "react";
import '../../../styles/app/components/modalBody.css';
import { isTrueUploadedFileTrue } from '../../core/workingWithFile';
import { Modal, Button } from "react-bootstrap";
import { Image,Col,Row,Dropdown } from "react-bootstrap";
import filePng from '../../files/images/icons/file.png';

class BasicModal extends Component{

    state = {
        modalSize: "lg",
        isShow: true,
        isImage: "data:image",
        message: "",
        applicationTaskId: "",
        file: ""
    }

    handleClose = () =>{
        this.setState({
            isShow: false
        });
    }

    handleChange = (e) =>{
      this.setState({
        message: e.target.value
      })
    }

    sendMessage = () =>{
      let taskId = document.getElementById("taskId").value;
      let errorMessageContent = document.getElementById("message-error")
      this.state.applicationTaskId = parseInt(taskId);
      if(this.state.message === ""){
        errorMessageContent.innerText = "Sahə tələbolunandır";
      }else{
        errorMessageContent.innerText = "";
        this.props.saveTaskMessage(this.state);
        this.setState({ message: "" })
      }
    }

    handleUploadFile = () =>{
      var uploadFileInput = document.getElementById("file");
      uploadFileInput.click();
    }

    handleMessageFile = (e) =>{

      let model = {};
      let taskId = document.getElementById("taskDetailTaskId").value;
      let photoFile = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(photoFile);

      reader.onloadend  =  () => {

        if(!isTrueUploadedFileTrue(reader.result)){
          document.getElementById("unsupportedFileTypeError").innerText = "File tipi dəstəklənmir!";
        }
       else{
            document.getElementById("unsupportedFileTypeError").innerText = "";
            model = {
              taskMessageFileBase64: reader.result,
              applicationTaskId: parseInt(taskId)
            }
            this.props.saveTaskMessageFile(model);
         }
      }
    }

    removeMessage = (e) =>{
      document.getElementById(e).remove();
      this.props.removeTaskMessage(e);
    }

  render(){
    let { taskDetailInfo } = this.props;
    /*let webRootPath = "https://localhost:44378/files/";*/
    let webRootPath = "http://88.198.193.132/TaskManagementWebApi/files/";
    return (
        <Fragment>        
          <Modal show={this.state.isShow} onHide={this.handleClose} size={this.state.modalSize} id="showMod">
            <Modal.Header closeButton>
              <Modal.Title>Ətraflı Məlumat</Modal.Title>
            </Modal.Header>
            <Modal.Body className="taskDetailContent">
                <section>
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-body" style={{paddingTop:"0px",paddingBottom:"0px"}}>
                            <div  style={{paddingTop:"15px",display:"flex"}}>
                              <div className=" mb-3" style={{width:"33%"}}>
                                  <Dropdown>
                                      <Dropdown.Toggle className="mr-3 mb-3 col-md-6 detailDropDowns" disabled={true}>{taskDetailInfo.application.applicationName}</Dropdown.Toggle>
                                  </Dropdown>
                              </div>
                              <div className=" mb-3" style={{width:"33%",textAlign:"center"}}>
                                  <Dropdown>
                                      <Dropdown.Toggle className="mr-3 mb-3 col-md-6 detailDropDowns" disabled={true}>{taskDetailInfo.taskType.type}</Dropdown.Toggle>
                                  </Dropdown>
                              </div>
                              <div className=" mb-3" style={{width:"33%",textAlign:"right"}}>
                                  <Dropdown>
                                      <Dropdown.Toggle className="mr-3 mb-3 col-md-6 detailDropDowns" disabled={true}>{taskDetailInfo.taskPriority.priorityLevel}</Dropdown.Toggle>
                                  </Dropdown>
                              </div>
                            </div>
                            <div className="form-row">
                            <div
                                  className="col-md-12 mb-3" className="taskHeaderLabel" style={{width:"100%"}}>
                                  <label>
                                  Qısa Başlıq
                                  </label>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="validationCustom203"
                                      placeholder="Qısa Başlıq"
                                      name="shortDescription"
                                      disabled={true}
                                      value={taskDetailInfo.shortDescription}
                                      readOnly={true}
                                  />
                              </div>
                              <div
                                className="col-md-12 mb-3" style={{paddingTop:"10px"}}>
                                <label htmlFor="validationCustom04" className="taskHeaderLabel">Haqqında</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="validationCustom04"
                                  placeholder="Haqqında"
                                  name="longDescription"
                                  rows={4}
                                  disabled={true}
                                  value={taskDetailInfo.longDescription}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="taskFileContent">
                  {
                    taskDetailInfo.taskDetailFiles.length !== 0 && <h5 style={{fontWeight:"bold",color:"#663399",marginTop:"15px"}}>Tapşırıqa aid file-lar.</h5>
                  }
                  {
                    <Row>
                      {taskDetailInfo.taskDetailFiles !== null
                        ? 
                        taskDetailInfo.taskDetailFiles.map((file) => (
                            <Col md="4" key={Math.random(0,100)}>
                              <div
                                style={{ marginTop: "1%" }}
                                key={Math.random}
                              >
                                <a href={webRootPath + file.fileName} target={file.fileName.includes("Images") !== false ? "blank" : ""}>
                                <Image
                                    src={file.fileName.includes("Images") !== false ? webRootPath + file.fileName : filePng}
                                    thumbnail
                                    style={{
                                      width: "100%",
                                      height: "10vh",
                                    }}
                                  />
                                </a>
                              </div>
                            </Col>
                          ))
                        : 
                        null}
                    </Row>
                  }
                </section>
                <section className="usersMessagingSection" style={{marginTop:"10px"}}>
                  <div className="messages" style={{maxHeight:"330px",overflow:"scroll",overflowX:"unset"}}>
                  <input value={taskDetailInfo.id} id="taskId"  hidden readOnly/>
                  
                    {
                      taskDetailInfo.taskMessages !== undefined
                      ?
                      taskDetailInfo.taskMessages.map(mes=>
                      <div className="executorMessage" key={Math.random(0,200)}>
                        <div id={mes.id}>
                        {
                          mes.message.includes("Images")
                          ?
                          <div style={{marginTop:"5px"}}>
                            <span style={{color:"#663399",fontWeight:"bold"}}>{mes.applicationUser.name}{" "}{mes.applicationUser.surname}:</span>
                            {
                              <a href={webRootPath + mes.message} target={mes.message.includes("Images") !== false ? "blank" : ""}>
                                <img src={webRootPath + mes.message} alt="message-photo" style={{width:"10%",marginLeft:"10px"}}/>
                              </a>
                            }
                           {
                              mes.isShowRemoveIcon === true 
                              ?
                              <span style={{position:"absolute",right:"5.5%",color:"rgb(102, 51, 153)",fontWeight:"bold"}} className="removeMessage" title="Sil" onClick={()=>this.removeMessage(mes.id)}>x</span>
                              :
                              null
                            }
                          </div>
                          :
                          (mes.taskMessageFileName !== null) && (mes.taskMessageFileName.includes("Excels") || mes.taskMessageFileName.includes("Words") || mes.taskMessageFileName.includes("Pdfs")) 
                          ?
                          <div style={{marginTop:"5px"}}>
                            <span style={{color:"#663399",fontWeight:"bold"}}>{mes.applicationUser.name}{" "}{mes.applicationUser.surname}:</span>
                            {
                              <a href={webRootPath + mes.message} download target={mes.message.includes("Images") !== false ? "blank" : ""}>
                                <img src={filePng} alt="message-photo" style={{width:"10%",marginLeft:"10px",height:"45px"}}/>
                              </a>
                            }
                            {
                              mes.isShowRemoveIcon === true 
                              ?
                              <span style={{position:"absolute",right:"5.5%",color:"rgb(102, 51, 153)",fontWeight:"bold"}} className="removeMessage" title="Sil" onClick={()=>this.removeMessage(mes.id)}>x</span>
                              :
                              null
                            }
                          </div>
                          :
                          <div>
                            <p style={{color:"#663399",fontWeight:"bold",margin:"0px"}}>
                              {mes.applicationUser.name}{" "}{mes.applicationUser.surname}:{" "} 
                              <span style={{color:"red"}} >{mes.message}</span>
                              {
                                mes.isShowRemoveIcon === true 
                                ?
                                <span style={{position:"absolute",right:"5.5%"}} className="removeMessage" title="Sil" onClick={()=>this.removeMessage(mes.id)}>x</span>
                                :
                                null 
                              }
                            </p> 
                          </div>
                        }
                        </div>
                      </div>
                      )
                      :
                      null
                    }
                  </div>
                  <input value={taskDetailInfo.id} hidden id="taskDetailTaskId" readOnly/>
                    <div className="form-group">
                      <label style={{fontSize:"18px"}}>Təsvir</label>
                      <textarea 
                        name="message" 
                        id="message" 
                        className="form-control"
                        value={this.state.message}
                        onChange={this.handleChange}
                      />
                      <span style={{fontWeight:"bold",color:"red",fontSize:"16px"}} id="unsupportedFileTypeError"></span>
                      <span className="text-danger" id="message-error"></span>
                    </div>
                    <div className="form-group" style={{textAlign:"right"}}>
                    <i className="i-File-Download" style={{fontSize:"22px",marginRight:"10px"}} id="uploadFileIcon" onClick={this.handleUploadFile}></i>
                      {
                        taskDetailInfo.taskStatusId !== 3
                        ?
                        <button className="btn btn-danger" onClick={this.sendMessage}>Göndər</button>
                        :
                        null
                      }
                      <input type="file" id="file" onChange={this.handleMessageFile} hidden/>
                    </div>
                </section>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose} id="closeModal">
                Bağla
            </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
  }
  
};

export default BasicModal;
