export function getRequestHeaderWithToken(){
    let storage = JSON.parse(localStorage.getItem("tokenDetail"));
    let headers={
        'Authorization': `Bearer ${storage != null ? storage.token : ""}`
    }
    return headers;
}

export function removeUserInfoFromLocalStorage(){
    localStorage.removeItem("tokenDetail");
}

export function getUserEmail(){
    let userInfo = JSON.parse(localStorage.getItem("tokenDetail"));
    if(userInfo){
        return userInfo.email
    }
}

export function saveUserDataInLocalstorage(isAuthorized,token){
    
    if(isAuthorized){
        var crm = {
          token: token
        }
        localStorage.setItem('tokenDetail',JSON.stringify(crm))
    }
}