import { API_BASE } from '../../config/env';
import axios from 'axios';
import { getRequestHeaderWithToken ,removeUserInfoFromLocalStorage} from '../../core/localStorage';

export const FETCH_COMPANY_PENDING = "FETCH_COMPANY_PENDING";
export const FETCH_COMPANY_FULFILLED = "FETCH_COMPANY_FULFILLED";
export const FETCH_COMPANY_REJECTED = "FETCH_COMPANY_REJECTED";

export const CREATE_USER_PENDING = "CREATE_USER_PENDING";
export const CREATE_USER_FULFILLED = "CREATE_USER_FULFILLED";
export const CREATE_USER_REJECTED = "CREATE_USER_REJECTED";

export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_FULFILLED = "LOGIN_USER_FULFILLED";
export const LOGIN_USER_REJECTED = "LOGIN_USER_REJECTED";

export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_FULFILLED = "GET_USER_FULFILLED";
export const GET_USER_REJECTED = "GET_USER_REJECTED";

export const GET_USER_ROLE_PENDING = "GET_USER_ROLE_PENDING";
export const GET_USER_ROLE_FULFILLED = "GET_USER_ROLE_FULFILLED";
export const GET_USER_ROLE_REJECTED = "GET_USER_ROLE_REJECTED";

export const GET_USER_ROLE_FOR_CARDSROUTING_PENDING = "GET_USER_ROLE_FOR_CARDSROUTING_PENDING";
export const GET_USER_ROLE_FOR_CARDSROUTING_FULFILLED = "GET_USER_ROLE_FOR_CARDSROUTING_FULFILLED";
export const GET_USER_ROLE_FOR_CARDSROUTING_REJECTED = "GET_USER_ROLE_FOR_CARDSROUTING_REJECTED";

export const GET_NEW_USERS_PENDING = "GET_NEW_USERS_PENDING";
export const GET_NEW_USERS_FULFILLED = "GET_NEW_USERS_FULFILLED";
export const GET_NEW_USERS_REJECTED = "GET_NEW_USERS_REJECTED";

export const GET_APPROVED_USERS_PENDING = "GET_APPROVED_USERS_PENDING";
export const GET_APPROVED_USERS_FULFILLED = "GET_APPROVED_USERS_FULFILLED";
export const GET_APPROVED_USERS_REJECTED = "GET_APPROVED_USERS_REJECTED";

export const LOCK_USER_PENDING = "LOCK_USER_PENDING";
export const LOCK_USER_FULFILLED = "LOCK_USER_FULFILLED";
export const LOCK_USER_REJECTED = "LOCK_USER_REJECTED";

export const SEARCHING_USER_PENDING = "SEARCHING_USER_PENDING";
export const SEARCHING_USER_FULFILLED = "SEARCHING_USER_FULFILLED";
export const SEARCHING_USER_REJECTED = "SEARCHING_USER_REJECTED";

export const ASSIGN_ROLE_TO_USER_PENDING = "ASSIGN_ROLE_TO_USER_PENDING";
export const ASSIGN_ROLE_TO_USER_FULFILLED = "ASSIGN_ROLE_TO_USER_FULFILLED";
export const ASSIGN_ROLE_TO_USER_REJECTED = "ASSIGN_ROLE_TO_USER_REJECTED";

export const SEND_RESTORE_PASSWORD_CODE_PENDING = "SEND_RESTORE_PASSWORD_CODE_PENDING";
export const SEND_RESTORE_PASSWORD_CODE_FULFILLED = "SEND_RESTORE_PASSWORD_CODE_FULFILLED";
export const SEND_RESTORE_PASSWORD_CODE_REJECTED = "SEND_RESTORE_PASSWORD_CODE_REJECTED";

export const RESTORE_USER_PASSWORD_PENDING = "RESTORE_USER_PASSWORD_PENDING";
export const RESTORE_USER_PASSWORD_FULFILLED = "RESTORE_USER_PASSWORD_FULFILLED";
export const RESTORE_USER_PASSWORD_REJECTED = "RESTORE_USER_PASSWORD_REJECTED";

export const GET_USER_ROLE_FOR_APP_ROUTING_PENDING = "GET_USER_ROLE_FOR_APP_ROUTING_PENDING";
export const GET_USER_ROLE_FOR_APP_ROUTING_FULFILLED = "GET_USER_ROLE_FOR_APP_ROUTING_FULFILLED";
export const GET_USER_ROLE_FOR_APP_ROUTING_REJECTED = "GET_USER_ROLE_FOR_APP_ROUTING_REJECTED";

export function fetchCompanies(){
    return dispatch=>{
        dispatch({
            type: "FETCH_COMPANY",
            payload : axios.get(`${API_BASE}/UserActions/GetCompanies`)
            .then(companies => companies.data)
        });
    };
};

export function createUser(model){
    return dispatch =>{
        dispatch({
            type : "CREATE_USER",
            payload : axios.post(`${API_BASE}/UserActions/AddUser`,model)
            .then(result=>result.data)
        })
    }
}

export function loginUser(model){
    return dispatch=>{
        dispatch({
            type: "LOGIN_USER",
            payload : axios.post(`${API_BASE}/UserActions/LoginUser`,model)
            .then(result => result.data)
        });
    };
};

export function getUserDetail(){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "GET_USER",
            payload: axios.get(`${API_BASE}/UserActions/GetUserDetail`,{headers})
            .then(user=>user.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
            })
        })
    }
}

export function getUserRole(){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "GET_USER_ROLE",
            payload: axios.get(`${API_BASE}/UserActions/GetUserRole`,{headers})
            .then(user=>user.data)
        })
        .catch(function(error){
            if (error.response && error.response.status === 401) {
                removeUserInfoFromLocalStorage()
                window.location = "/TaskManagement";
            }
        })
    }
}

export function getUserRoleForAppRouting(){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "GET_USER_ROLE_FOR_APP_ROUTING",
            payload: axios.get(`${API_BASE}/UserActions/GetUserRoleForRouting`,{headers})
            .then(user=>user.data)
        })
    }
}

export function getUserRoleForCardsRouting(){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "GET_USER_ROLE_FOR_CARDSROUTING",
            payload: axios.get(`${API_BASE}/UserActions/GetUserRole`,{headers})
            .then(user=>user.data)
        })
    }
}

export function getNewUsers(page){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "GET_NEW_USERS",
            payload: axios.get(`${API_BASE}/UserActions/GetNewUsers/${page}`,{headers})
            .then(user=>user.data)
        })
        .catch(function(error){
            if (error.response && error.response.status === 401) {
                removeUserInfoFromLocalStorage()
                window.location = "/TaskManagement";
            }
            else{
                window.location = "/TaskManagement/404";
            }
        })
    }
}

export function getApprovedUsers(page){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "GET_APPROVED_USERS",
            payload: axios.get(`${API_BASE}/UserActions/GetApprovedUsers/${page}`,{headers})
            .then(user=>user.data)
        })
        .catch(function(error){
            if (error.response && error.response.status === 401) {
                removeUserInfoFromLocalStorage()
                window.location = "/TaskManagement";
            }
        })
    }
}

export function assignRoleToUser(model){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "ASSIGN_ROLE_TO_USER",
            payload : axios.post(`${API_BASE}/UserActions/AssignRoleToUser`, model,{headers})
            .then(result => result.data)
        })
        .catch(function(error){
            if (error.response && error.response.status === 401) {
                removeUserInfoFromLocalStorage()
                window.location = "/TaskManagement";
            }
            else{
                window.location = "/TaskManagement/404";
            }
        });
    };
};

export function lockUser(user){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "LOCK_USER",
            payload : axios.post(`${API_BASE}/UserActions/LockUser`, user,{headers})
            .then(result => result.data)
        })
        .catch(function(error){
            if (error.response && error.response.status === 401) {
                removeUserInfoFromLocalStorage()
                window.location = "/TaskManagement";
            }
            else{
                window.location = "/TaskManagement/404";
            }
        });
    };
};

export function searchingUser(searchPattern){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "SEARCHING_USER",
            payload: axios.get(`${API_BASE}/UserActions/GetUserBySearchPattern/${searchPattern}`,{headers})
            .then(user=>user.data)
        })
        .catch(function(error){
            if (error.response && error.response.status === 401) {
                removeUserInfoFromLocalStorage()
                window.location = "/TaskManagement";
            }
           
        })
    }
}

export function sendRestorePasswordCode(email){
    return dispatch=>{
        dispatch({
            type: "SEND_RESTORE_PASSWORD_CODE",
            payload: axios.get(`${API_BASE}/UserActions/SendResetPasswordCode/${email}`)
            .then(model =>model.data)
        })
    }
}

export function restoreUserPassword(model){
    return dispatch=>{
        dispatch({
            type: "RESTORE_USER_PASSWORD",
            payload: axios.post(`${API_BASE}/UserActions/RestorePassword`,model)
            .then(model =>model.data)
        })
    }
}
