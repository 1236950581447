import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import apertechlogo from '../../files/images/template/apertechlogo.png';
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { Link, Redirect,Route } from "react-router-dom";
import '../../../styles/app/components/layoutHeader.css';
import { connect } from "react-redux";
import { getUserDetail } from '../../redux/actions/UserActions';
import { mainSearchTask } from '../../redux/actions/TaskActions';
import { merge } from "lodash";
import ErrorAlert from '../../components/notificationComponents/ErrorAlert';

class Layout1Header extends Component {

  state = {
    pattern: ""
  }

  handleMenuClick = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layout1Settings: {
          leftSidebar: {
            open: settings.layout1Settings.leftSidebar.secondaryNavOpen
              ? true
              : !settings.layout1Settings.leftSidebar.open,
            secondaryNavOpen: false
          }
        }
      })
    );
  };

  handleSearchPattern = (e) =>{
    this.setState({ pattern: e.target.value })
  }

  handleSearchUser = () =>{
    var searchValue = document.getElementById("searchPattern").value;
    if(searchValue === undefined || searchValue === "" || searchValue === null){
      document.getElementById("searchErrorMessage").innerText="Sahə tələbolunandır";
    }
    else{
      this.props.mainSearchTask(this.state.pattern);
      document.getElementById("searchErrorMessage").innerText="";
    }
    
  }

  toggleFullScreen = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreen) document.documentElement.requestFullscreen();
      else document.exitFullscreen();
    }
  };

  signOut = () => {
    window.location = "/";
    localStorage.removeItem('tokenDetail');
  }

  componentDidMount(){
    var storageInfo = JSON.parse(localStorage.getItem('tokenDetail'));
    if(storageInfo){
      this.props.getUserDetail(storageInfo.email);
    }
  }


  render() {
    
    return (
      <div className="main-header">
        {this.props.TaskReducer.isMainSearch === true && <Redirect to={{pathname: '/new_tasks'}}/>}
        {this.props.TaskReducer.nothingToShow === false && <ErrorAlert title="Axtardığınız parametrə uyğun nəticə tapılmadı" redirectRoute=""/>}
        <div className="logo">
          <Link to="/main_content">
            <img src={apertechlogo} alt="" style={{width:"75px",height:"63px"}}/>
          </Link>
        </div>

        <div className="d-none d-lg-flex align-items-center">
          <input
            type="text"
            className="form-control form-control-rounded col-md-12 ml-3 mr-3"
            placeholder="Axtarış..."
            name="searchPattern"
            id="searchPattern"
            onChange={this.handleSearchPattern}
            value={this.state.pattern}
          />
         
          <button className="btn btn-primary" onClick={this.handleSearchUser}>Axtarış</button>
          <span className="text-danger" id="searchErrorMessage" style={{marginLeft:"10px"}}></span>
        </div>
        <div className="header-part-right">
          <i
            className="i-Full-Screen header-icon d-none d-sm-inline-block"
            data-fullscreen
            onClick={this.toggleFullScreen}
          ></i>
          <div className="user col align-self-end">
            <Dropdown>
              <DropdownToggle
                as="span"
                className="toggle-hidden cursor-pointer"
              >
                <img
                  src="https://p.kindpng.com/picc/s/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png"
                  id="userDropdown"
                  alt=""
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
              </DropdownToggle>
              <DropdownMenu id="headerDropdown">
                <div className="dropdown-header">
                  <i className="i-Lock-User mr-1" style={{fontSize:'16px'}}></i>
                  {
                    this.props.UserReducer.model
                    &&
                    <span style={{fontSize:'16px'}}>{this.props.UserReducer.model.name} {this.props.UserReducer.model.surname}</span> 
                  }
                </div>
                <Link
                  to="/"
                  className="dropdown-item cursor-pointer"
                  onClick={this.signOut}
                  style={{fontSize:'16px'}}
                >
                  Çıxış
                </Link>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ TaskReducer,UserReducer }) => {
  return{
    TaskReducer,
    UserReducer
  }
}

export default 
  connect(mapStateToProps, {
    getUserDetail,
    mainSearchTask
  })(Layout1Header)

