import React, { Component } from "react";
import "../styles/app/app.scss";
import { connect } from 'react-redux';
import { getUserRoleForAppRouting } from './redux/actions/UserActions';
import { Route, Redirect} from "react-router-dom";
import Error from './pages/Error';
import Cards from "./components/layout/Cards";
import SignIn from './pages/Signin';
import SignUp from './pages/Signup';
import Tasks from './pages/Tasks';
import CompletedTasks from './pages/CompletedTasks';
import Layout1 from '../app/GullLayout/Layout1/Layout1';
import CreateTask from '../app/pages/CreateTask';
import Loading from "./components/notificationComponents/Loading";
import Users from './pages/Users';
import ForgotPassword from './pages/ForgotPassword';
import RestorePassword from './pages/RestorePassword';

class App extends Component{

  state = {
    token: undefined,
    userRole: "",
    admin: "Admin"
  }

  componentDidMount(){
    this.props.getUserRoleForAppRouting();
    var userData = JSON.parse(localStorage.getItem("tokenDetail"));
    if(userData){
      this.setState({ token: userData.token })
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({ userRole: nextProps.UserReducer.appRoutingUserRole })
  }

  render(){

    let { isAuthorized,authorizationFetching,token } = this.props.UserReducer;

    if(isAuthorized){
      var crm = {
        token: token
      }
      localStorage.setItem('tokenDetail',JSON.stringify(crm));
    }
    
    return (
      <div>
        
        {
          authorizationFetching === true 
          ? 
          <Loading styleLoading={"left:45%"}/> 
          : 
          (this.state.token === undefined) &&  (isAuthorized === false) 
          ?
          <div>
              <Route exact path="/" component={SignIn}/>
              <Route exact path="/registration" component={SignUp}/>
              <Route exact path="/reset_password" component={ForgotPassword}/>
              <Route exact path="/restore_password/:userId" component={RestorePassword}/>
          </div>
          :
          null
        }
        {
          isAuthorized === true || (this.state.token === "" || this.state.token !== undefined)
          ?
          <div>
              <Layout1 />
              <Route exact path="/" render={() => (
                <Redirect to="/main_content" />
              )} />
              <div className="main-content-wrap d-flex flex-column sidenav-open">
                <div className="main-content">
                      <Route path="/404" component={Error}/>
                      <Route path="/write_task" component={CreateTask}/>
                      <Route path="/new_tasks" component={Tasks}/>
                      <Route path="/new_tasks_from_mail/:taskId" component={Tasks}/>
                      <Route path="/completed_tasks" component={CompletedTasks}/>
                      <Route path="/main_content" component={Cards} />
                      {
                        this.props.UserReducer.appRoutingUserRole === this.state.admin
                        ?
                        <Route path="/all_users" component={Users} />
                        :
                        null
                      }
                </div>
              </div>
            </div>
          :
          null
        }
      </div>
    );
  }
}

const mapStateToProps = ({ UserReducer }) =>{
  return{
    UserReducer
  }
}

const mapDispatchToProps = {
  getUserRoleForAppRouting
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
