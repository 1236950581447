import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTaskDropdownItems } from '../redux/actions/TaskActions';
import TaskForm from '../components/taskmanager/TaskForm';
import Loading from '../components/notificationComponents/Loading';

export class CreateTask extends Component {

    componentDidMount(){
        this.props.fetchTaskDropdownItems();
    }

    render() {
        let { model,fetching,error }  = this.props.TaskReducer;
        let companyApplicationDtos = null;
        let taskPriorityDtos = null;
        let taskTypeDtos = null;
        if(model !== undefined){
            companyApplicationDtos = model.companyApplicationDtos;
            taskPriorityDtos = model.taskPriorityDtos;
            taskTypeDtos = model.taskTypeDtos;
        }
        return (
            <div style={{marginLeft:'18%!important'}}>
                { fetching && <Loading styleLoading={{left:'40%'}}/> }
                <TaskForm 
                    companyApplicationDtos={companyApplicationDtos}
                    taskPriorityDtos={taskPriorityDtos}
                    taskTypeDtos={taskTypeDtos}
                    fetching = {fetching}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ TaskReducer }) =>{
    return{
        TaskReducer
    }
}

export default connect(mapStateToProps,{ fetchTaskDropdownItems })(CreateTask);
