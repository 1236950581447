import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { restoreUserPassword } from '../redux/actions/UserActions';
import Loading from '../components/notificationComponents/Loading';
import SuccessfulAlert from '../components/notificationComponents/SuccessfulAlert';
import ErrorAlert from '../components/notificationComponents/ErrorAlert';
import apertechlogo from '../files/images/template/apertechlogo.png';

const RestorePasswordSchema = yup.object().shape({
    password: yup
    .string()
    .min(6, "Şifrə 6 simvoldan uzun olmalıdır")
    .required("Sahə tələbolunandır"),
    confirimPassword: yup
    .string()
    .required("Təkrar şifrəni daxil edin")
    .oneOf([yup.ref("password")], "Şifrələr uyğun deyil")
  });

class RestorePassword extends Component {
  state = {
    password: "",
    confirimPassword: "",
    errorMessage: "",
    requestError: {},
    isSuccessfuleRestored: false,
    fetching: false
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (value) => {
    
    let { match } = this.props;
    let model = {
      id: match.params.userId,
      password: value.confirimPassword
    }

    this.props.restoreUserPassword(model);
  };

  componentWillReceiveProps(nextProps){

    this.setState({
      isSuccessfuleRestored: nextProps.UserReducer.isSuccessfulRestorePassword,
      errorMessage: nextProps.UserReducer.restoreUserPasswordError,
      requestError: nextProps.UserReducer.error,
      fetching: nextProps.UserReducer.fetching
    })
    if(nextProps.UserReducer.restoreUserPasswordError !== undefined || nextProps.UserReducer.restoreUserPasswordError !== "" || nextProps.UserReducer.restoreUserPasswordError !== null){
      document.getElementById("errorMessage").innerText = nextProps.UserReducer.restoreUserPasswordError;
    }
  }

  render() {
    let { fetching,isSuccessfuleRestored,requestError } = this.state;
    return (
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: "url('https://www.asimot.com/wp-content/uploads/2018/09/utilities-2000x800.jpg')"
        }}
      >
        {
          fetching === true ? <Loading styleLoading={"left:45%"}/> : null
        }
        {
          isSuccessfuleRestored === true 
          ? 
          <SuccessfulAlert title={"Şifrəniz uğurla yeniləndi."} redirectRoute="/"/> 
          : 
          null
        }
        {
          Object.keys(requestError).length !== 0 && <ErrorAlert title="Sorğu zamanı xəta baş verdi" redirectRoute=""/>
        }
        <div className="auth-content">
          <div className="card o-hidden" style={{opacity:fetching !== false ? .5 : 1}}>
            <div className="row">
              <div className="col-md-12">
                <div className="p-4">
                  <div className="auth-logo text-center mb-4">
                    <img src={apertechlogo} alt="" style={{width:"113px",height:"94px"}}/>
                  </div>
                  <span id="errorMessage" className="text-danger"></span>
                  <Formik
                    initialValues={this.state}
                    validationSchema={RestorePasswordSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="password">Şifrə</label>
                          <input
                            className="form-control form-control-rounded position-relative"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            disabled={fetching}
                          />
                          {errors.password && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="confirimPassword">Təkrar Şifrə</label>
                          <input
                            className="form-control form-control-rounded position-relative"
                            type="password"
                            name="confirimPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirimPassword}
                            disabled={fetching}
                          />
                          {errors.confirimPassword && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.confirimPassword}
                            </div>
                          )}
                        </div>
                        <button
                          className="btn btn-rounded btn-primary btn-block mt-2"
                          type="submit"
                          disabled={fetching}
                        >
                          Təsdiqlə
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ UserReducer }) =>{
  return{
    UserReducer
  }
}

const mapDispatchToProps = {
  restoreUserPassword
}

export default connect(mapStateToProps,mapDispatchToProps)(RestorePassword);

