import React,{Component} from 'react';
import SweetAlert from "sweetalert2-react";

class SuccessfulAlert extends Component{

    state = {
        success: true
    }

    toggleAlert = () => {
        window.location = this.props.redirectRoute !== undefined ? this.props.redirectRoute : ''
    };

    render(){
        let { success } = this.state;
        return(
            <div>
                  <SweetAlert
                    show={success}
                    title={this.props.title}
                    type="success"
                    text={this.props.message}
                    onConfirm={() => this.toggleAlert("success")}
                  />
            </div>
        );
    };
};

export default SuccessfulAlert;