import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import TaskDetail from '../taskmanager/TaskDetail';

export class CompletedTasksTable extends Component {
   
    state = {
        taskNumberPattern:""
    }

    getTaskDetail = (id) =>{
        this.props.getTaskDetailInfo(id);
    }

    onHandleSearch = (e) =>{
        this.setState({ taskNumberPattern: e.target.value})
    }

    onClickSearch = () =>{
        if(this.state.taskNumberPattern === ""){
            document.getElementById("searchValidationError").innerText = "Sahə tələbolunandır"; 
        }
        else{
            document.getElementById("searchValidationError").innerText = ""; 
            this.props.getTaskByTaskNumberForCompletedTasks(this.state.taskNumberPattern);
        }
    }

    render() {
        let { allCompletedTasks } = this.props;
        return (
        <div>
            {
                this.props.taskDetailIsFetched === true ? <TaskDetail taskDetailInfo={this.props.taskDetailInfo} /> : null
            }
            <div>
            <div id="task-manager-list">
                <div className="content">
                <div className="card" id="card">
                    <div className="card-header bg-transparent ul-task-manager__header-inline">
                    <h6 className="card-title task-title">Task Manager</h6>
                    </div>

                    <div className="card-body" id="card-body">
                    <div className="search ul-task-manager__search-inline align-items-center">
                        <nav className="navbar" style={{ padding: "0" }}>
                        <div className="form-inline">
                            <span style={{paddingRight:"10px"}}>ID:</span>
                            <input
                                className="form-control mr-sm-2"
                                id="taskNumberPattern"
                                type="search"
                                placeholder="axtarış"
                                aria-label="Search"
                                value={this.state.taskNumberPattern}
                                onChange={this.onHandleSearch}
                            />
                            <button className="btn btn-primary" onClick={this.onClickSearch}>Axtar</button>
                        </div>
                        <span id="searchValidationError" style={{color:"red",marginLeft:"10px"}}></span>
                        </nav>
                    </div>

                    <div
                        className="table-responsive"
                        style={{
                        height:
                        allCompletedTasks !== undefined &&
                        allCompletedTasks.length === 1
                            ? "26vh"
                            : "",
                        }}
                    >
                        <table
                        id="names"
                        className=" table table-bordered custom-sm-width"
                        >
                        <thead>
                            <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tapşırıq</th>
                            <th scope="col">Prioritet</th>
                            <th scope="col">Göndərilmə Tarixi</th>
                            <th scope="col">İcra Edilmə Tarixi</th>
                            <th scope="col">Status</th>
                            <th scope="col">İcraçı</th>
                            <th scope="col">Ətraflı</th>
                            </tr>
                        </thead>
                        <tbody id="names">
                            {allCompletedTasks &&
                            allCompletedTasks.map((task, ind) => (
                                <tr key={ind} id="names">
                                <th scope="row" className="head-width">
                                {task.taskNumber}
                                </th>
                                <td className="collection-item">
                                    <div className="font-weight-bold">
                                    <span>
                                        <b>
                                        {task.applicationUser.company.companyName}
                                        </b>
                                        :
                                        <span
                                        style={{
                                            color: "#663399",
                                            paddingLeft: "5px",
                                        }}
                                        >
                                        {task.applicationUser.name}{" "}
                                        {task.applicationUser.surname}
                                        </span>
                                    </span>
                                    </div>
                                    <div className="text-muted">
                                    <span style={{ fontWeight: "bold" }}>
                                        {task.taskType.type}:
                                    </span>
                                    <span style={{ paddingLeft: "5px" }}>
                                        {task.shortDescription}
                                    </span>
                                    </div>
                                </td>
                                <td className="custom-align">
                                    <div className="btn-group ">
                                    {task.taskPriority.id === 1 ? (
                                        <span
                                        className="text-danger"
                                        style={{ fontWeight: "bold" }}
                                        >
                                        {task.taskPriority.priorityLevel}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {task.taskPriority.id === 2 ? (
                                        <span
                                        style={{
                                            color: "#663399",
                                            fontWeight: "bold",
                                        }}
                                        >
                                        {task.taskPriority.priorityLevel}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {task.taskPriority.id === 3 ? (
                                        <span
                                        style={{
                                            color: "#4caf50",
                                            fontWeight: "bold",
                                        }}
                                        >
                                        {task.taskPriority.priorityLevel}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    </div>
                                </td>

                                <td className="custom-align">
                                    <div className="d-inline-flex align-items-center calendar align-middle">
                                    <i className="i-Calendar-4"></i>
                                    <span className="">{task.strRegDate}</span>
                                    </div>
                                </td>
                                <td className="custom-align">
                                        <div className="d-inline-flex align-items-center calendar align-middle">
                                        <i className="i-Calendar-4"></i>
                                        <span className="">
                                            {task.strEditDate}
                                        </span>
                                        </div>
                                    </td>
                                <td>
                                <span style={{color:'green',fontWeight:"bold"}} >İcra Edilib</span>
                                </td>
                                    <td style={{ fontWeight: "bold" }}>
                                    {task.taskAcceptedUser.name}{" "}
                                    {task.taskAcceptedUser.surname}
                                    </td>
                                <td
                                    className="custom-align"
                                    valign="middle"
                                    style={{
                                    textAlign: "center",
                                    paddingTop: "18px",
                                    }}
                                >
                                    <i
                                    className="i-Information showTaskDetail"
                                    style={{ fontSize: "28px", color: "#663399" }}
                                    onClick={() => this.getTaskDetail(task.id)}
                                    ></i>
                                </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>

                    {
                        this.props.searchResult === null && this.props.pagination !== null
                        ?
                        <div className="card-footer text-muted">
                            <div className="d-flex flex-wrap justify-content-between align-items-center py-1">
                                <span style={{fontWeight:"bold"}}>Ümumi: {" " + this.props.pagination.totalCount}</span>
                                <ReactPaginate
                                previousLabel={"Geriyə"}
                                nextLabel={"İrəli"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.props.pagination.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                forcePage={this.props.pagination.currentPage}
                                containerClassName={"pagination pagination-lg"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                />
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                </div>
            </div>
            </div>
        </div>
        );
    }
}

export default CompletedTasksTable;
