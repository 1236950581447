import { combineReducers } from "redux";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import UserReducer from './UserReducer';
import TaskReducer from './TaskReducer';

const RootReducer = combineReducers({
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  UserReducer,
  TaskReducer
});

export default RootReducer;
