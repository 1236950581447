import React, { Component } from "react";
import { getNewUsers,assignRoleToUser,getApprovedUsers,lockUser,searchingUser } from '../../redux/actions/UserActions';
import SuccessfulAlert from '../notificationComponents/SuccessfulAlert';
import Loading from '../notificationComponents/Loading';
import { connect } from 'react-redux';
import ReactPaginate from "react-paginate";
import { Dropdown } from "react-bootstrap";
import { MdMoreVert } from "react-icons/md";
import UserEditor from "./UserEditor";
import swal from "sweetalert2";

class UsersListContainer extends Component {
  state = {
    showEditorDialog: false,
    searchQuery: "",
    dialogValues: null,
    userList: [],
    appUserRoles: [],
    operationIsSuccessful: false,
    fetching: false,
    roleId: null,
    tabIsActive: "",
    pagination: null,
    searchPattern: "",
    searchResult: null
  };

  componentDidMount(){
    let page = 1;
    this.props.getNewUsers(page);
  }

  componentWillReceiveProps(nextProps){
    this.setState({ 
      userList:  nextProps.UserReducer.users, 
      appUserRoles: nextProps.UserReducer.appRoles,
      operationIsSuccessful: nextProps.UserReducer.operationIsSuccessful,
      fetching: nextProps.UserReducer.fetching,
      roleId: nextProps.UserReducer.users.length !== 0 ? nextProps.UserReducer.users[0].identityRoleId : null,
      pagination: nextProps.UserReducer.pagination,
      searchResult: nextProps.UserReducer.searchResult
    });
  }

  handlePageClick = data => {
    let page = data.selected +1;
    if(this.state.roleId === null){
      this.props.getNewUsers(page);
    }
    else{
      this.props.getApprovedUsers(page);
    }
  };

  handleSearchPattern = (e) =>{
    this.setState({ searchPattern: e.target.value })
  }

  toggleEditorDialog = arg => {
    if (arg && arg.toString())
      this.setState({ showEditorDialog: arg, dialogValues: null });
    else
      this.setState({
        showEditorDialog: !this.state.showEditorDialog,
        dialogValues: null
      });
  };

  handleEditContact = dialogValues => {
    this.setState({ dialogValues, showEditorDialog: true });
  };

  handleDeleteContact = (user) =>{
    this.props.lockUser(user);
  }

  onHandleUserRoleSelect = (e) =>{
    let appRoleId = e.target.value.substring(0,e.target.value.indexOf("/"));
    let userId = e.target.value.substring(e.target.value.indexOf("/")+1);
    let model = {
      id: userId,
      roleId : appRoleId
    }
    this.props.assignRoleToUser(model);
  }

  onHandleEditUserRoleSelect = (e) => {
    let appRoleId = e.target.value.substring(0,e.target.value.indexOf("/"));
    let userId = e.target.value.substring(e.target.value.indexOf("/")+1);
    let model = {
      id: userId,
      roleId : appRoleId
    }
    this.props.assignRoleToUser(model);
  }

  handleGetNewUsers = () =>{
    let page = 1;
    this.props.getNewUsers(page);
  }

  handleGetApprovedUsers = () =>{
    let page = 1;
    this.props.getApprovedUsers(page);
  }

  handleSearchUser = () =>{
    this.props.searchingUser(this.state.searchPattern)
  }

  render() {
    
    let {
      userList = [],
      dialogValues,
      showEditorDialog,
      appUserRoles,
      operationIsSuccessful,
      fetching,
      roleId,
      pagination,
      searchResult
    } = this.state;
    let activeStyleNewUsers = roleId === null ? "active" : "";
    let activeStyleApprovedUsers = roleId !== null ? "active" : "";
    return (
      <div>
        {
          operationIsSuccessful === true ? <SuccessfulAlert message="Əməliyyat uğurlu yerinə yetirildi" title="Uğurlu" redirectRoute="/TaskManagement/all_users"/> : null
        }
        {
          fetching === true
          ?
          <Loading styleLoading={"left:45%"}/>
          :
          <div>
          <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header  gradient-purple-indigo  0-hidden pb-80">
                <div className="pt-4">
                  <div className="row">
                    <h4 className="col-md-4 text-white">İstifadəçilər</h4>
                    <input
                      type="text"
                      className="form-control form-control-rounded col-md-4 ml-3 mr-3"
                      placeholder="İstifadəçilərin axtarışı..."
                      name="searchPattern"
                      id="searchPattern"
                      value={this.state.searchPattern}
                      onChange={this.handleSearchPattern}
                    />
                    <button className="btn btn-warning" onClick={this.handleSearchUser}>Axtarış</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="ul-contact-list-body">
                  <div className="ul-contact-main-content">
                    <div className="ul-contact-left-side">
                      <div className="card">
                        <div className="card-body">
                          <div className="ul-contact-list">
                            <div className="contact-close-mobile-icon float-right mb-2">
                              <i className="i-Close-Window text-15 font-weight-600"></i>
                            </div>
                            <div
                              className="list-group"
                              id="list-tab"
                              role="tablist"
                            >
                              <span
                                className={"list-group-item list-group-item-action border-0 " + activeStyleNewUsers }
                                id="list-home-list"
                                data-toggle="list"
                                role="tab"
                                aria-controls="home"
                                onClick={this.handleGetNewUsers}
                              >
                                <i className="nav-icon i-Business-Mens"> </i>
                                Yeni İstifadəçilər
                              </span>
                              <span
                                className={"list-group-item list-group-item-action border-0 " + activeStyleApprovedUsers }
                                id="list-profile-list"
                                data-toggle="list"
                                role="tab"
                                aria-controls="profile"
                                onClick={this.handleGetApprovedUsers}
                              >
                                <i className="nav-icon i-Conference"> </i>
                                Təsdiqlənmiş İstifadəçilər
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ul-contact-content">
                      <div className="card">
                        <div className="card-body">
                          <div className="float-left">
                            <i className="nav-icon i-Align-Justify-All text-25 ul-contact-mobile-icon"></i>
                          </div>
                          <div
                            className="tab-content ul-contact-list-table--label"
                            id="nav-tabContent"
                          >
                            {/* <!-- all-contact  --> */}
                            <div className="tab-pane fade show active">
                              <div className=" text-left">
                                <div className="table-responsive">
                                  <table
                                    id="contact_list_table"
                                    className="display table  table-borderless ul-contact-list-table w-100"
                                  >
                                    <thead>
                                      <tr className="border-bottom">
                                        <th scope="col">Ad</th>
                                        <th scope="col">Soyad</th>
                                        <th scope="col">Ata Adı</th>
                                        <th scope="col">İstifadəçi Adı</th>
                                        <th scope="col">Kompaniya</th>
                                        {
                                          roleId !== null
                                          ?
                                          <th>Səlahiyyəti</th>
                                          :
                                          null
                                        }
                                        {
                                          roleId === null
                                          ?
                                          <th scope="col">Rol Əlavə Etmək</th>
                                          :
                                          null
                                        }
                                        {
                                          roleId !== null
                                          ?
                                          <th scope="col">Əməliyyatlar</th>
                                          :
                                          null
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {userList
                                        .map((user, ind) => (
                                          <tr key={ind}>
                                            <td>
                                              {user.name}
                                            </td>
                                            <td>{user.surname}</td>
                                            <td>{user.patronymic}</td>
                                            <td>{user.email}</td>
                                            <td>{user.company.companyName}</td>
                                            {
                                              roleId !== null
                                              ?
                                              <td>{user.identityRole !== null ? user.identityRole.name : <span style={{color:"red",fontWeight:"bold"}}>Yoxdur</span>}</td>
                                              :
                                              null
                                            }
                                            {
                                              roleId === null
                                              ?
                                              <td className="custom-align" valign="middle">
                                                <select
                                                    className="custom-select task-manager-list-select"
                                                    id="inputGroupSelect01"
                                                    onChange={this.onHandleUserRoleSelect}
                                                >
                                                  <option>Seçim Edin</option>
                                                  {
                                                    appUserRoles !== undefined && appUserRoles.length !== 0
                                                    ?
                                                    appUserRoles.map(role =>
                                                        <option value={role.id + "/" + user.id} key={role.id}>{role.name}</option>
                                                    )
                                                    :
                                                    null
                                                  }
                                                  </select>
                                              </td>
                                              :
                                              null
                                            }
                                                {
                                                  roleId !== null
                                                  ?
                                                  <td valign="middle">
                                                     <Dropdown alignRight>
                                                       <Dropdown.Toggle
                                                         as="span"
                                                         className="cursor-pointer toggle-hidden"
                                                       >
                                                         <MdMoreVert
                                                           size={18}
                                                         ></MdMoreVert>
                                                       </Dropdown.Toggle>
                                                       <Dropdown.Menu>
                                                         <Dropdown.Item
                                                           onClick={() =>
                                                             this.handleEditContact(
                                                               user
                                                             )
                                                           }
                                                         >
                                                           <i className="nav-icon i-Pen-2 text-success font-weight-bold mr-2"></i>
                                                           Redaktə Et
                                                         </Dropdown.Item>
                                                         <Dropdown.Item
                                                           onClick={() => {
                                                             swal
                                                               .fire({
                                                                 title:
                                                                   "Xəbərdarlıq !",
                                                                 text:
                                                                   "İstifadəçini silmək istədiyinizdən əminsinizmi ?",
                                                                 icon: "warning",
                                                                 type: "question",
                                                                 showCancelButton: true,
                                                                 confirmButtonColor:
                                                                   "#3085d6",
                                                                 cancelButtonColor:
                                                                   "#d33",
                                                                 confirmButtonText:
                                                                   "Bəli, Sil!",
                                                                 cancelButtonText: "Xeyir"
                                                               })
                                                               .then(result => {
                                                                 if (result.value) {
                                                                   this.handleDeleteContact(
                                                                     user
                                                                   );
                                                                 } else {
                                                                   swal.fire(
                                                                     "Bildiriş!",
                                                                     "Əməliyyat ləğv edildi.",
                                                                     "error"
                                                                   );
                                                                 }
                                                               });
                                                           }}
                                                         >
                                                           <i className="nav-icon i-Close-Window text-danger font-weight-bold mr-2"></i>
                                                           İstifadəçini Sil
                                                         </Dropdown.Item>
                                                       </Dropdown.Menu>
                                                     </Dropdown>
                                                  </td>
                                                  :
                                                  null
                                              }
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            searchResult === null && pagination !== null
                            ?
                            <div className="d-flex justify-content-end">
                              <ReactPaginate
                                previousLabel={"Geriyə"}
                                nextLabel={"İrəli"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pagination.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                forcePage={pagination.currentPage}
                                containerClassName={"pagination pagination-lg"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserEditor
          show={showEditorDialog}
          onHandleEditUserRoleSelect={this.onHandleEditUserRoleSelect}
          toggleEditorDialog={this.toggleEditorDialog}
          appUserRoles = {appUserRoles}
          initialValues={dialogValues}
          handleFormSubmit={this.handleFormSubmit}
        ></UserEditor>
        </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({UserReducer}) =>{
  return{
    UserReducer
  }
}

const mapDispatchToProps = {
  getNewUsers,
  getApprovedUsers,
  assignRoleToUser,
  lockUser,
  searchingUser
}

export default connect(mapStateToProps,mapDispatchToProps)(UsersListContainer);
