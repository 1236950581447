import React, { Component } from 'react';
import ReactPaginate from "react-paginate";
import SuccessfulAlert from "../notificationComponents/SuccessfulAlert";
import { Dropdown } from "react-bootstrap";

export class TasksContainerTable extends Component {

  state = {
    admin: "Admin",
    consultant: "Consultant",
    consultantManager: "ConsultantManager",
    companyWorker: "CompanyWorker",
    taskStatusType: "",
    statusId: null,
    userId: null,
    applicationTaskId: null,
    isShowExecutorSelectBox: false,
    executorFullName: "",
    statusChanged: "",
    taskNumberPattern: ""
  };

  onSelectTaskStatus = (e) => {
    let statusId = e.substring(0,e.indexOf('.'));
    let taskId = e.substring(e.indexOf('.') + 1,e.indexOf("/"));
    let userId = e.substring(e.indexOf("/") +1);
    var dropdownTogle = document.getElementById(`statusDropdown${taskId}`);

    this.state.statusId = parseInt(statusId);
    this.state.applicationTaskId = parseInt(taskId);
    this.state.userId = userId;
    dropdownTogle.innerText = statusId == 2 ? "Qəbul Edilib" : statusId == 3 ? "İcra Edilib" : "Seçim Edin...";
    this.props.addAcceptedTask(this.state);
  }

  showExecutorSelectBox = () =>{
    this.setState({
      isShowExecutorSelectBox: true
    })
  }

  onSelectTaskStatusExecutor = (e) =>{
    let { applicationUserDtos } = this.props.applicationTasks[0];
    let userId = e.substring(0,e.indexOf('.'));
    let taskId = e.substring(e.indexOf('.')+1);
    var user = applicationUserDtos.filter(x=>x.id == userId)[0];
    document.getElementById(`userDropdown${taskId}`).innerText = user.name + " " + user.surname;
    this.state.userId = userId;
    this.state.applicationTaskId = parseInt(taskId);
    this.props.assignTaskToUser(this.state);
  }

  handlePageClick = data => {
    let currentPage = data.disabled;
    let id = document.getElementById("taskStatusIdForPage").value;
    let page = data.selected +1;
    this.props.getUserNotAcceptedTasks(id,page)
    this.setState({ currentPage });
  };

  
  getTaskDetail = (id) =>{
    this.props.getTaskDetailInfo(id);
  }

  onHandleSearch = (e) =>{
    this.setState({ taskNumberPattern: e.target.value})
  }

  onClickSearch = (e) =>{
    if(this.state.taskNumberPattern === ""){
        document.getElementById("searchValidationError").innerText = "Sahə tələbolunandır"; 
    }
    else{
        document.getElementById("searchValidationError").innerText = ""; 
        this.props.getTaskByTaskNumber(this.state.taskNumberPattern)
    } 
  }

  render() {
    let taskStatuses = this.props.taskStatuses;
    let applicationTasks = this.props.applicationTasks;
    var userRole = this.props.applicationTasks !== null && this.props.applicationTasks.length !== 0 && this.props.applicationTasks[0].userRole;
    let taskStatusId = this.props.applicationTasks !== null && this.props.applicationTasks.length !== 0 && this.props.applicationTasks[0].taskStatusId;
    let { admin, consultant,consultantManager,companyWorker } = this.state;
    let { isTaskStatusSuccessfulChanged } = this.props;
    return (
      <div>
        {
          isTaskStatusSuccessfulChanged === true ? <SuccessfulAlert message="Əməliyyat uğurlu yerinə yetirildi" title="Uğurlu"/> : null
        }
        <input value={taskStatusId} id="taskStatusIdForPage" hidden readOnly/>
        <div id="task-manager-list">
          <div className="content">
            <div className="card" id="card">
              <div className="card-header bg-transparent ul-task-manager__header-inline">
                <h6 className="card-title task-title">Task Manager</h6>
              </div>

              <div className="card-body" id="card-body">
                <div className="search ul-task-manager__search-inline align-items-center">
                  <nav className="navbar" style={{padding:"0"}}>
                  <div className="form-inline">
                      <span style={{paddingRight:"10px"}}>ID:</span>
                      <input
                        className="form-control mr-sm-2"
                        id="taskNumberPattern"
                        type="search"
                        placeholder="axtarış"
                        aria-label="Search"
                        value={this.state.taskNumberPattern}
                        onChange={this.onHandleSearch}
                      />
                      <button className="btn btn-primary" onClick={this.onClickSearch}>Axtar</button>
                      <span id="searchValidationError" style={{color:"red",marginLeft:"10px"}}></span>
                  </div>
                  </nav>
                </div>

                <div className="table-responsive" style={{height: applicationTasks !== null && applicationTasks.length === 1 ? "26vh" : ""}}>
                  <table
                    id="names"
                    className=" table table-bordered custom-sm-width"
                  >
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Tapşırıq</th>
                        <th scope="col">Prioritet</th>
                        <th scope="col">Göndərilmə Tarixi</th>
                        {
                          // if task status id is 1 it is task sended from user and dont need to show edit data
                          // edit date need show only accepted and done task types
                          taskStatusId != 1
                          ?
                          <th scope="col">Yenilənmə Tarixi</th>
                          :
                          null
                        }
                        {
                          taskStatusId !== 1
                          ?
                          <th scope="col">Aralıq Müddət</th>
                          :
                          null
                        }
                        <th scope="col">Status</th>
                        {
                          // if user admin or consultant can change accepted user 
                          // and only can change from accpeted or done task type
                          (userRole === admin || userRole === consultantManager) && (taskStatusId !== 3)
                          ?
                          <th scope="col">Yönləndir</th>
                          :
                          null
                        }
                        {
                          (userRole !== consultant) && (this.props.currentTaskId !== 1)
                          ?
                          <th scope="col">İcraçı</th>
                          :
                          null
                        }
                        <th scope="col">Ətraflı</th>
                      </tr>
                    </thead>
                    <tbody id="names">
                      {
                        applicationTasks
                        &&
                        applicationTasks.map((task, ind) => (
                        <tr key={ind} id="names">
                          <th scope="row" className="head-width">
                            {task.taskNumber}
                          </th>
                          <td className="collection-item">
                            <div className="font-weight-bold">
                              <span><b>{task.applicationUser.company.companyName}</b>: 
                                <span style={{color:"#663399",paddingLeft:"5px"}}>{task.applicationUser.name } {task.applicationUser.surname}</span>
                              </span>
                            </div>
                            <div className="text-muted">
                              <span style={{fontWeight:"bold"}}>{task.taskType.type}:</span>
                              <span style={{paddingLeft:"5px"}}>{task.shortDescription}</span>
                            </div>
                          </td>
                          <td className="custom-align">
                            <div className="btn-group ">
                                {
                                  task.taskPriority.id === 1 ? <span className="text-danger" style={{fontWeight:"bold"}}>{task.taskPriority.priorityLevel}</span> : ""
                                }
                                {
                                  task.taskPriority.id === 2 ? <span style={{color:"#663399",fontWeight:"bold"}}>{task.taskPriority.priorityLevel}</span> : ""
                                }
                                {
                                  task.taskPriority.id === 3 ? <span style={{color:"#4caf50",fontWeight:"bold"}}>{task.taskPriority.priorityLevel}</span> : ""
                                }
                            </div>
                          </td>

                          <td className="custom-align">
                            <div className="d-inline-flex align-items-center calendar align-middle">
                              <i className="i-Calendar-4"></i>
                              <span className="">{task.strRegDate}</span>
                            </div>
                          </td>
                          {
                            // if task status id is 1 it is task sended from user and dont need to show edit data
                            // edit date need show only accepted and done task types
                            taskStatusId != 1
                            ?
                            <td className="custom-align">
                            <div className="d-inline-flex align-items-center calendar align-middle">
                              <i className="i-Calendar-4"></i>
                              <span className="">{task.strEditDate}</span>
                            </div>
                          </td>
                          :
                          null
                          }
                          {
                            taskStatusId !== 1
                            ?
                           <td>{task.drtDate}{" "} gün</td>
                            :
                            null
                          }
                          <td className="custom-align">
                            {
                              taskStatuses !== undefined && (task.userRole !== companyWorker)
                              ?
                              ["outline-primary"].map((variant, i) => (
                                task.taskStatusId != 3
                                ?
                                <Dropdown key={i} onSelect={this.onSelectTaskStatus} >
                                    <Dropdown.Toggle variant={variant} className="mr-3 mb-3 col-md-12" id={`statusDropdown${task.id}`}>
                                      {task.taskStatusId === 2 && isTaskStatusSuccessfulChanged === false ? "Qəbul Edilib" 
                                      : isTaskStatusSuccessfulChanged === true ? "Icra edilib" : "Seçim Edin..."}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu id="dropdownShow">
                                      {
                                        taskStatuses 
                                        &&
                                        taskStatuses.map(status=>
                                         status.id === 2 && task.taskStatusId === 1
                                         ?
                                         <Dropdown.Item eventKey={status.id+"." +task.id + "/" + task.applicationUser.id} key={status.id}>Qəbul Et</Dropdown.Item>
                                         :
                                         status.id === 3 && (task.taskStatusId === 2 || task.taskStatusId === 1 )
                                         ?
                                         <Dropdown.Item eventKey={status.id+"." +task.id + "/" + task.applicationUser.id} key={status.id}>İcra Edilib</Dropdown.Item>
                                         :
                                         null
                                        )
                                      }
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                <span style={{color:'green',fontWeight:"bold"}} key={i}>İcra Edilib</span>
                              ))
                              :
                              <span>
                                {
                                  task.taskStatusId === 1 ? <span style={{color:'red',fontWeight:"bold"}}>Göndərilib</span>
                                  :
                                  task.taskStatusId === 2 ? <span style={{color:'#663399',fontWeight:"bold"}}>Qəbul edilib</span>
                                  :
                                  task.taskStatusId === 3 ? <span style={{color:'green',fontWeight:"bold"}}>İcra Edilib</span>
                                  :
                                  null
                                }
                              </span>
                            }                          
                          </td>
                          {
                            // if user admin or consultant can change accepted user 
                            // and only can change from accpeted or done task type
                            (userRole === admin || userRole === consultantManager) && (taskStatusId !== 3)
                            ?
                            <td>
                              <div style={{display:"inline-block",width:"100%"}}>
                                 {
                                    ["outline-primary"].map((variant, i) => (
                                      <Dropdown key={i} onSelect={this.onSelectTaskStatusExecutor} >
                                          <Dropdown.Toggle variant={variant} className="mr-3 mb-3 col-md-12" id={`userDropdown${task.id}`}>Seçim Edin...</Dropdown.Toggle>
                                          <Dropdown.Menu id="userDropdownToggle">
                                            {
                                              task.applicationUserDtos 
                                              &&
                                              task.applicationUserDtos.map(user=>
                                               <Dropdown.Item eventKey={user.id + "." + task.id } key={user.id}>{user.name}{" "}{user.surname}</Dropdown.Item>
                                              )
                                            }
                                          </Dropdown.Menu>
                                      </Dropdown>
                                    ))
                                 }
                              </div>
                            </td>
                            :
                            null
                          }
                          {
                            (userRole !== consultant && this.props.currentTaskId !== 1) && (task.taskAcceptedUser !== null)
                            ?
                            <td style={{fontWeight:'bold'}}>{task.taskAcceptedUser.name}{" "}{task.taskAcceptedUser.surname}</td>
                            :
                            null
                          }
                          <td className="custom-align" valign="middle" style={{textAlign:"center",paddingTop:"18px"}}>
                            <i className="i-Information showTaskDetail" style={{fontSize:"28px",color:"#663399"}} onClick={()=>this.getTaskDetail(task.id)}></i>                           
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>

              {
                this.props.searchResult === null && this.props.pagination !== null
                ?
                <div className="card-footer text-muted">
                  <div className="d-flex flex-wrap justify-content-between align-items-center py-1">
                    <span style={{fontWeight:"bold"}}>Ümumi: {" " + this.props.pagination.totalCount}</span>
                    <ReactPaginate
                      previousLabel={"Geriyə"}
                      nextLabel={"İrəli"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.pagination.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      forcePage={this.props.pagination.currentPage}
                      containerClassName={"pagination pagination-lg"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
               :
               null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TasksContainerTable



