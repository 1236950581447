import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getUserRoleForCardsRouting,getUserRoleForAppRouting } from '../../redux/actions/UserActions';

class AppCard extends Component {

  state = {
    isAdmin: false,
    adminRoutes: [
      {
        icon: "i-Add-User",
        path: "/new_tasks",
        title: "İstifadəçilər",
        path: "/all_users"
      },
      {
        icon: "i-Library",
        title: "Sorğu Yaratmaq",
        path: "/write_task"
      },
      {
        icon: "i-Split-Horizontal-2-Window",
        title: "Sorğularım",
        path: "/new_tasks"
      },
      {
        icon: "i-Magnifi-Glass",
        title: "Ümumi Baxış",
        path: "/completed_tasks"
      }
    ],
    allUsersRoutes: [
        {
          icon: "i-Library",
          title: "Sorğu Yaratmaq",
          path: "/write_task"
        },
        {
          icon: "i-Split-Horizontal-2-Window",
          title: "Sorğularım",
          path: "/new_tasks"
        },
        {
          icon: "i-Magnifi-Glass",
          title: "Ümumi Baxış",
          path: "/completed_tasks"
        }
    ]
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.UserReducer.cardsRoutinUserRole === "Admin"){
      this.setState({ isAdmin: true })
    }
  }

  componentDidMount(){
    this.props.getUserRoleForCardsRouting();
    this.props.getUserRoleForAppRouting();
  }

  render() {
    let { isAdmin,adminRoutes,allUsersRoutes } = this.state;
    return (
      <Fragment>
        <div className="row">
          {
            isAdmin === true 
            ?
            adminRoutes.map((card, index) => (
           
              <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                  <Link to={card.path}>
                      <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                          <div className="card-body text-center">
                              <i className={card.icon}></i>
                              <div className="content">
                                  <p className="text-muted mt-2 mb-0 text-capitalize">
                                  {card.subtitle}
                                  </p>
                                  <p className="lead text-primary text-24 mb-2 text-capitalize">
                                  {card.title}
                                  </p>
                              </div>
                          </div>
                      </div>
                  </Link>
              </div>
          ))
          :
          allUsersRoutes.map((card, index) => (
           
              <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                  <Link to={card.path}>
                      <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                          <div className="card-body text-center">
                              <i className={card.icon}></i>
                              <div className="content">
                                  <p className="text-muted mt-2 mb-0 text-capitalize">
                                  {card.subtitle}
                                  </p>
                                  <p className="lead text-primary text-24 mb-2 text-capitalize">
                                  {card.title}
                                  </p>
                              </div>
                          </div>
                      </div>
                  </Link>
              </div>
          ))
        }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ UserReducer }) =>{
  return{
    UserReducer
  }
}

const mapDispatchToProps = {
  getUserRoleForCardsRouting,
  getUserRoleForAppRouting
}

export default connect(mapStateToProps,mapDispatchToProps)(AppCard);
