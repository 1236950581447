import React, { Component } from "react";
import '../../styles/app/components/signup.css';
import apertechlogo from '../files/images/template/apertechlogo.png';
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { fetchCompanies,createUser } from '../redux/actions/UserActions';
import SuccessfulAlert from '../components/notificationComponents/SuccessfulAlert';
import ErrorAlert from '../components/notificationComponents/ErrorAlert';
import Loading from '../components/notificationComponents/Loading';

class Signup extends Component {
  state = {
    email: "",
    name: "",
    surname: "",
    patronymic: "",
    password: "",
    repassword: "",
    companyName: "",
    companyId: ""
  };
  
  handleCompanyDropDown = (e) =>{
    this.setState({
      companyId : parseInt(e),
      companyName : this.props.UserReducer.companies.filter((x) => x.id == e)[0].companyName
    });
  }

  handleSubmit = (values) => {
    this.props.createUser(values);
  };

  componentWillMount(){
    this.props.fetchCompanies();
  };

  render() {
    let { companies,modelStateErrors,isSuccessful,fetching,error } = this.props.UserReducer;
    return (
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: "url('https://www.asimot.com/wp-content/uploads/2018/09/utilities-2000x800.jpg')"
        }}
      >
        
        {
          isSuccessful && <SuccessfulAlert message="İstifadəçinin qeydiyyatı uğurla tamamlandı" title="Uğurlu" redirectRoute="/"/>
        }
        {
          Object.keys(error).length !== 0 ? <ErrorAlert message="İstifadəçinin qeydiyyatı zamanı xəta baş verdi" title="Xəta" isRedirect={false} /> : null
        }
        {
          fetching && <Loading loading={fetching}/>
        }
        <div className="auth-content" style={{opacity:fetching != false ? .5 : 1}}>
          <div className="card o-hidden">
            <div className="row">           
              <div
                className="col-md-6 text-center "
                style={{
                  backgroundSize: "cover",
                  backgroundImage: "url('https://png.pngtree.com/thumb_back/fw800/background/20190221/ourmid/pngtree-the-internet-light-effect-line-science-fiction-poster-background-image_23029.jpg')"
                }}
              >
                <div className="pl-3 auth-right" style={{flexDirection:"inherit",marginLeft:"32%"}}>
                  <div className="auth-logo text-center mt-4">
                    <img src={apertechlogo} alt="" style={{width:"113px",height:"94px"}}/>
                  </div>
                  <div className="flex-grow-1"></div>
                  <div className="flex-grow-1"></div>
                  
                </div>
              </div>

              <div className="col-md-6">
                <div className="p-4">
                  <h1 className="mb-3 text-18">İstifadəçi Qeydiyyat</h1>
                  {
                    modelStateErrors && modelStateErrors.map(error=>(
                      <span style={{color:"red"}} key={Math.random(0,100)}>{error}</span>
                    ))
                  }
                  <Formik
                    initialValues={this.state}
                    validationSchema={SignupSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          {["outline-primary"].map((variant, i) => (
                            <Dropdown key={i} onSelect={this.handleCompanyDropDown}>
                                <div hidden>{this.state.companyName !== "" ? values.companyName = this.state.companyName : null}</div>
                                <div hidden>{this.state.companyId !== "" ? values.companyId = this.state.companyId : null}</div>
                                <Dropdown.Toggle variant={variant} className="mr-3 mb-3 col-md-12" disabled={fetching}>{ this.state.companyName !== "" ? this.state.companyName : "Şirkət adı" }</Dropdown.Toggle>
                                <Dropdown.Menu className="companyDropdown">
                                    {
                                      companies.length !== 0 
                                      ?
                                      companies.map(company=>
                                        <Dropdown.Item key={Math.random(0,100)} eventKey={company.id}>{company.companyName}</Dropdown.Item>
                                      )
                                      :
                                      null
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                          ))}
                          {errors.companyName && touched.companyName && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.companyName}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Ad</label>
                          <input
                            className="form-control form-control-rounded"
                            name="name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            disabled={fetching}
                          />
                          {errors.name && touched.name && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="surname">Soyad</label>
                          <input
                            className="form-control form-control-rounded"
                            name="surname"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.surname}
                            disabled={fetching}
                          />
                          {errors.surname && touched.surname && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.surname}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="patronymic">Ata Adı</label>
                          <input
                            className="form-control form-control-rounded"
                            name="patronymic"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patronymic}
                            disabled={fetching}
                          />
                          {errors.patronymic && touched.patronymic && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.patronymic}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            name="email"
                            className="form-control form-control-rounded"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            disabled={fetching}
                          />
                          {errors.email && touched.email && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <input
                            name="password"
                            className="form-control form-control-rounded"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            disabled={fetching}
                          />
                          {errors.password && touched.password && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="repassword">Retype password</label>
                          <input
                            name="repassword"
                            className="form-control form-control-rounded"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.repassword}
                            disabled={fetching}
                          />
                          {errors.repassword && touched.repassword && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.repassword}
                            </div>
                          )}
                        </div>
                        <button
                          className="btn btn-primary btn-block btn-rounded mt-3"
                          type="submit"
                          disabled={fetching}
                        >
                          Qeydiyyat
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const SignupSchema = yup.object().shape({
  name: yup.string().required("Sahə tələbolunandır"),
  surname: yup.string().required("Sahə tələbolunandır"),
  patronymic:yup.string().required("Sahə tələbolunandır"),
  companyName:yup.string().required("Sahə tələbolunandır"),
  email: yup
    .string()
    .email("Emailin formatı düzgün deyil")
    .required("Sahə tələbolunandır"),
  password: yup
    .string()
    .min(6, "Şifrə 6 simvoldan uzun olmalıdır")
    .required("Sahə tələbolunandır"),
  repassword: yup
    .string()
    .required("Təkrar şifrəni daxil edin")
    .oneOf([yup.ref("password")], "Şifrələr uyğun deyil")
});

const mapStateToProps = ({ UserReducer }) => {
	return {
		UserReducer
	}
};

const mapDispatchToProps = {
  fetchCompanies,
  createUser
};

export default connect(mapStateToProps,mapDispatchToProps)(Signup);
