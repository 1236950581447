import React, { Component } from 'react';
import "../../../styles/app/components/taskList.css";
import { Row, Col, Tab,  Nav } from "react-bootstrap";
import TasksContainerTable from './TasksContainerTable';
import TaskDetail from './TaskDetail';

export class TaskList extends Component {

    state = {
        activeKey: "1",
    }

    getSelectedTypeTasks = (id) =>{
        let page = 1;
        this.props.getUserNotAcceptedTasks(id,page);
    }

    render() {
        let taskStatuses = this.props.taskStatuses;
        let applicationTasks = this.props.applicationTasks;
        this.state.activeKey = this.props.taskStatusId != "" ? this.props.taskStatusId : "1";
        return (
            <div>
                {
                    this.props.taskDetailIsFetched === true 
                    ? 
                    <TaskDetail 
                        taskDetailInfo={this.props.taskDetailInfo} 
                        saveTaskMessage = {this.props.saveTaskMessage} 
                        saveTaskMessageFile = {this.props.saveTaskMessageFile}
                        removeTaskMessage={this.props.removeTaskMessage}
                    /> 
                    : 
                    null
                }
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.activeKey}>
                            <Nav variant="pills" className="px-2" id="tabContainer">
                                {
                                    taskStatuses !== undefined && this.props.isMainSearch === false
                                    &&
                                    taskStatuses.map(status=>
                                        <div key={status.id}>
                                            <Nav.Item className="mr-2">
                                                <Nav.Link eventKey={status.id} onClick={()=>this.getSelectedTypeTasks(status.id)}>{status.statusType}</Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    )
                                }
                            </Nav>
                            <Tab.Content>
                                {
                                    taskStatuses !== undefined
                                    &&
                                    taskStatuses.map(status=>
                                        <Tab.Pane eventKey={status.id} key={status.id}>
                                            <TasksContainerTable 
                                                taskStatuses={taskStatuses} 
                                                applicationTasks={applicationTasks}
                                                addAcceptedTask = {this.props.addAcceptedTask}
                                                isTaskStatusSuccessfulChanged = { this.props.isTaskStatusSuccessfulChanged }
                                                assignTaskToUser = {this.props.assignTaskToUser}
                                                currentTaskId = {this.props.taskStatusId}
                                                getTaskDetailInfo={this.props.getTaskDetailInfo}
                                                isTaskAssignSuccessful={this.props.isTaskAssignSuccessful}
                                                getUserNotAcceptedTasks={this.props.getUserNotAcceptedTasks}
                                                pagination={this.props.pagination}
                                                getTaskByTaskNumber={this.props.getTaskByTaskNumber}
                                                searchResult={this.props.searchResult}
                                            />
                                        </Tab.Pane>
                                    )
                                }
                            </Tab.Content>
                            </Tab.Container>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default TaskList;
