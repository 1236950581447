import axios from 'axios';
import { getRequestHeaderWithToken } from './localStorage';

export function getImageBase64(files){

    let imagesBase64 = [];
    for(let i = 0; i < files.length; i++){

        const reader = new FileReader();

        reader.onloadend = () => {

            imagesBase64.push(reader.result)
            console.log(reader.result)
        };
        reader.readAsDataURL(files[i]);
    }
    return imagesBase64;  
}

export async function Test(url){
    let blob = await fetch(url).then(r => r.blob());
    console.log(blob)
    return blob;
}

export function isTrueUploadedFileTrue(pattern){
    if(pattern === undefined || pattern === null || pattern === ""){
        return false;
    }
    else{
        if(pattern.includes("data:image") 
            || pattern.includes("data:application/pdf") 
            || pattern.includes("spreadsheetml") 
            || pattern.includes("wordprocessingml")
            || pattern.includes("msword")){
                return true;
            }
        else{
            return false;
        }
    }
}