import React from "react";
import { Modal, FormGroup, FormControl,FormLabel } from "react-bootstrap";
import { Formik } from "formik";

const ContactEditor = ({
  show,
  initialValues,
  toggleEditorDialog,
  handleFormSubmit,
  appUserRoles,
  onHandleEditUserRoleSelect
}) => {
  return (
    <Modal show={show} onHide={toggleEditorDialog} centered>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          İstifadəçi Səlahiyyətnin Yenilənməsi
        </h5>
        <button type="button" className="close" aria-label="Close" onClick={() => toggleEditorDialog(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={
            initialValues
              ? initialValues
              : { name: "", email: ""}
          }
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
          }) => (
            <form>
              <FormGroup>
              <FormLabel>Ad</FormLabel>
                <FormControl
                  value={values.name}
                  disabled={true}
                  readOnly
                ></FormControl>
              </FormGroup>
              <FormGroup>
              <FormLabel>Soyad</FormLabel>
                <FormControl
                  value={values.surname}
                  disabled={true}
                  readOnly
                ></FormControl>
              </FormGroup>
              <FormGroup>
              <FormLabel>İstifadəçi adı</FormLabel>
                <FormControl
                  value={values.email}
                  disabled={true}
                  readOnly
                ></FormControl>
              </FormGroup>
              <FormGroup controlId="exampleForm.ControlSelect2">
                <FormLabel>Yeni Səlahiyyət</FormLabel>
                <select className="custom-select task-manager-list-select" id="inputGroupSelect01" onChange={(e) => onHandleEditUserRoleSelect(e)}>
                  <option>Seçim Edin</option>
                  {
                    appUserRoles.map(role =>
                      <option value={role.id + "/" + values.id} key={role.id}>{role.name}</option>
                    )
                  }
                </select>
              </FormGroup>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => toggleEditorDialog(false)}>Bağla</button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ContactEditor;
