import React, { Component } from 'react';
import "../../styles/app/components/users.css";
import UsersListContainer from '../components/users/UsersListContainer';

export class Users extends Component {
    render() {
        return (
            <div>
                <UsersListContainer />
            </div>
        )
    }
}

export default Users;
