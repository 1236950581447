export const navigations = [
  {
    name: "Əsas Səhifə",
    description: "Əsas Səhifə",
    icon: "nav-icon i-Bar-Chart",
    path: "/TaskManagement/main_content",
    type: "link"
  },
  {
    name: "Texniki Dəstək",
    description: "Texniki Dəstək",
    type: "dropDown",
    icon: "i-Library",
    sub: [
      { icon: "i-Split-Horizontal-2-Window", name: "Sorğu Yaratmaq", path: "/TaskManagement/write_task", type: "link" },
    ]
  },
  {
    name: "Tapşırıqlar",
    description: "Lorem ipsum dolor sit.",
    icon: "i-File-Clipboard-File--Text",
    type: "dropDown",
    sub:[
      { icon: "i-Split-Horizontal-2-Window", name: "Mənim Tapşırıqlarım", path: "/TaskManagement/new_tasks", type: "link" },
      { icon: "i-Magnifi-Glass", name: "Ümumi Baxış", path: "/TaskManagement/completed_tasks", type: "link" }
    ]
  }
];
