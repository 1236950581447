import 
{
    FETCH_TASK_DROPDOWNS_PENDING,
    FETCH_TASK_DROPDOWNS_FULFILLED,
    FETCH_TASK_DROPDOWNS_REJECTED,
    ADD_TASK_PENDING,
    ADD_TASK_FULFILLED,
    ADD_TASK_REJECTED,
    GET_TASK_STATUSES_PENDING,
    GET_TASK_STATUSES_FULFILLED,
    GET_TASK_STATUSES_REJECTED,
    GET_NOT_ACCEPTED_TASKS_PENDING,
    GET_NOT_ACCEPTED_TASKS_FULFILLED,
    GET_NOT_ACCEPTED_TASKS_REJECTED,
    ADD_ACCEPTED_TASK_PENDING,
    ADD_ACCEPTED_TASK_FULFILLED,
    ADD_ACCEPTED_TASK_REJECTED,
    GET_TASK_DETAIL_PENDING,
    GET_TASK_DETAIL_FULFILLED,
    GET_TASK_DETAIL_REJECTED,
    SEND_TASK_MESSAGE_PENDING,
    SEND_TASK_MESSAGE_FULFILLED,
    SEND_TASK_MESSAGE_REJECTED,
    GET_ALL_COMPLETED_TASKS_PENDING,
    GET_ALL_COMPLETED_TASKS_FULFILLED,
    GET_ALL_COMPLETED_TASKS_REJECTED,
    ASSIGN_TASK_TO_USER_PENDING,
    ASSIGN_TASK_TO_USER_FULFILLED,
    GET_TASK_BY_TASKNUMBER_PENDING,
    GET_TASK_BY_TASKNUMBER_FULFILLED,
    GET_TASK_BY_TASKNUMBER_REJECTED,
    GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_PENDING,
    GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_FULFILLED,
    GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_REJECTED,
    SEND_TASK_MESSAGE_FILE_PENDING,
    SEND_TASK_MESSAGE_FILE_FULFILLED,
    SEND_TASK_MESSAGE_FILE_REJECTED,
    MAIN_SEARCH_TASK_PENDING,
    MAIN_SEARCH_TASK_FULFILLED,
    REMOVE_TASK_MESSAGE_PENDING,
    REMOVE_TASK_MESSAGE_FULFILLED,
    REMOVE_TASK_MESSAGE_REJECTED
} 
from '../actions/TaskActions';

const initialState = {
    fetching: false,
    taskDetailIsFetched: null,
    taskAddFetching:false,
    model: {},
    modelTaskStatuses:[],
    applicationTasks:[],
    allCompletedTasks:[],
    taskDetailInfo: null,
    pagination: null,
    searchResult: null,
    //here task status id need save panel as active
    userId: "",
    taskStatusId: 1,
    taskIsSuccessfulyAdded: false,
    isTaskStatusSuccessfulChanged: false,
    isTaskAssignSuccessful: false,
    taskAddingError:{},
    error: {},
    isMainSearch: false,
    nothingToShow: null
}

export default (state = initialState,action) =>{
    switch(action.type){

        //fetching datas for create task dropdowns
        case FETCH_TASK_DROPDOWNS_PENDING:
            return{
                ...state,
                fetching: true
            }
        case FETCH_TASK_DROPDOWNS_FULFILLED:
            return{
                ...state,
                fetching: false,
                model: action.payload
            }
        case FETCH_TASK_DROPDOWNS_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }

        //adding task
        case ADD_TASK_PENDING:
            return{
                ...state,
                taskAddFetching: true
            }
        case ADD_TASK_FULFILLED:
            return{
                ...state,
                taskAddFetching: false,
                taskIsSuccessfulyAdded: action.payload && action.payload.isSuccessfull
            }
        case ADD_TASK_REJECTED:
            return{
                ...state,
                taskAddFetching: false,
                error: action.payload
            }

        //getting task statuses
        case GET_TASK_STATUSES_PENDING:
            return{
                ...state
            }
        case GET_TASK_STATUSES_FULFILLED:
            return{
                ...state,
                modelTaskStatuses: action.payload && action.payload.model
            }
        case GET_TASK_STATUSES_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }
        
        //getting user not accepted tasks
        case GET_NOT_ACCEPTED_TASKS_PENDING:
            return{
                ...state,
                taskDetailIsFetched: null,
                fetching: true
            }
        case GET_NOT_ACCEPTED_TASKS_FULFILLED:
            return{
                ...state,
                applicationTasks: action.payload && action.payload.model,
                taskStatusId: action.payload && action.payload.additionalObject,
                pagination: action.payload && action.payload.paginationDto,
                searchResult: null,
                fetching: false
            }           
        case GET_NOT_ACCEPTED_TASKS_REJECTED:
            return{
                ...state,
                fetching: false,
                error:action.payload,
                
            }
        
        //adding accepted tasks
        case ADD_ACCEPTED_TASK_PENDING:
            return{
                ...state,
                fetching: true
            }
        case ADD_ACCEPTED_TASK_FULFILLED:
            return{
                ...state,
                isTaskStatusSuccessfulChanged: action.payload && action.payload.isSuccessfull,
                taskStatusId: action.payload && action.payload.model,
                fetching: false
            }
        case ADD_ACCEPTED_TASK_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }

        //assign task to user
        case ASSIGN_TASK_TO_USER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case ASSIGN_TASK_TO_USER_FULFILLED:
            return{
                ...state,
                isTaskStatusSuccessfulChanged: action.payload && action.payload.isSuccessfull,
                taskStatusId: action.payload && action.payload.model,
                fetching: false
            }
        case ASSIGN_TASK_TO_USER_FULFILLED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }

        //getting task detail info
        case GET_TASK_DETAIL_PENDING:
            return{
                ...state,
                fetching: true
            }
        case GET_TASK_DETAIL_FULFILLED:
            return{
                ...state,
                taskDetailInfo: action.payload && action.payload.model,
                taskDetailIsFetched: true,
                fetching: false,
            }
        case GET_TASK_DETAIL_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }
        
        //save task message
        case SEND_TASK_MESSAGE_PENDING:
            return{
                ...state
            }
        case SEND_TASK_MESSAGE_FULFILLED:
            return{
                ...state,
                taskDetailInfo: action.payload && action.payload.model,
                taskDetailIsFetched: true
            }
        case SEND_TASK_MESSAGE_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }

        // get all completed tasks
        case GET_ALL_COMPLETED_TASKS_PENDING:
            return{
                ...state,
                fetching: true
            }
        case GET_ALL_COMPLETED_TASKS_FULFILLED:
            return{
                ...state,
                allCompletedTasks: action.payload && action.payload.model,
                pagination: action.payload && action.payload.paginationDto,
                searchResult: null,
                fetching: false
            }
        case GET_ALL_COMPLETED_TASKS_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }
        
        //get task by task number
        case GET_TASK_BY_TASKNUMBER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case GET_TASK_BY_TASKNUMBER_FULFILLED:
            return{
                ...state,
                applicationTasks: action.payload && action.payload.model,
                fetching: false
            }
        case GET_TASK_BY_TASKNUMBER_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }

        //get task by task number for completed tasks
        case GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_PENDING:
            return{
                ...state,
                fetching: true
            }
        case GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_FULFILLED:
            return{
                ...state,
                allCompletedTasks: action.payload && action.payload.model,
                fetching: false
            }
        case GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }
        
        //save task message file
        case SEND_TASK_MESSAGE_FILE_PENDING:
            return{
                ...state
            }
        case SEND_TASK_MESSAGE_FILE_FULFILLED:
            return{
                ...state,
                taskDetailInfo: action.payload && action.payload.model,
                taskDetailIsFetched: true
            }
        case SEND_TASK_MESSAGE_FILE_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }
        
        // main search task
        case MAIN_SEARCH_TASK_PENDING:
            return{
                ...state,
                fetching: true,
                isMainSearch: false
            }
        case MAIN_SEARCH_TASK_FULFILLED:
            return{
                ...state,
                applicationTasks: action.payload && action.payload.model,
                isMainSearch: action.payload && action.payload.additionalObject,
                nothingToShow: action.payload && action.payload.additionalObject,
                pagination: null,
                fetching: false
            }

        //remove task message
        case REMOVE_TASK_MESSAGE_PENDING:
            return{
                ...state,
            }
        case REMOVE_TASK_MESSAGE_FULFILLED:
            return{
                ...state,
                taskDetailInfo: action.payload && action.payload.model,
                taskDetailIsFetched: true
            }
        case REMOVE_TASK_MESSAGE_REJECTED:
            return{
                ...state,
                error: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}