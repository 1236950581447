import React, { Component } from "react";
import { Formik } from "formik";
import apertechlogo from '../files/images/template/apertechlogo.png';
import * as yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginUser } from '../redux/actions/UserActions';
import Loading from '../components/notificationComponents/Loading';
import ErrorAlert from '../components/notificationComponents/ErrorAlert';

const SigninSchema = yup.object().shape({
  email: yup
    .string()
    .email("Emailin formatı düzgün deyil")
    .required("Sahə tələbolunandır"),
  password: yup
    .string()
    .min(6, "Şifrənin minimal uzunluğu 6 simvoldan ibarət olmalıdır")
    .required("Sahə tələbolunandır")
});

class Signin extends Component {
  state = {
    email: "",
    password: ""
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (value) => {
    this.props.loginUser(value);  
  };

  render() {
    let { modelStateErrors,fetching,error } = this.props.UserReducer;
    return (
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: "url('https://www.asimot.com/wp-content/uploads/2018/09/utilities-2000x800.jpg')"
        }}
      >
        {
          fetching && <Loading loading={fetching}/>
        }
        {
          Object.keys(error).length !== 0 ? <ErrorAlert message="Sistemə daxil olan zaman xəta baş verdi" title="Xəta" isRedirect={false}/> : null
        }
        <div className="auth-content" style={{opacity:fetching !== false ? .5 : 1}}>
          <div className="card o-hidden">
            <div className="row">
              <div className="col-md-6">
                <div className="p-4">
                  <div className="auth-logo text-center mb-4">
                    <img src={apertechlogo} alt="" style={{width:"113px",height:"94px"}}/>
                  </div>
                  <h1 className="mb-3 text-18">Daxil Ol</h1>
                  {
                    modelStateErrors && modelStateErrors.map(error=>(
                      <span style={{color:"red"}} key={Math.random(0,100)}>{error}</span>
                    ))
                  }
                  <Formik
                    initialValues={this.state}
                    validationSchema={SigninSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            className="form-control form-control-rounded position-relative"
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            disabled={fetching}
                          />
                          {errors.email && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Şifrə</label>
                          <input
                            className="form-control form-control-rounded"
                            placeholder="Şifrə"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            disabled={fetching}
                          />
                          {errors.password && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <button
                          className="btn btn-rounded btn-primary btn-block mt-2"
                          type="submit"
                          disabled={fetching}
                        >
                          Daxil Ol
                        </button>
                      </form>
                    )}
                  </Formik>

                  <div className="mt-3 text-center">
                    {
                      !fetching 
                      && 
                      <Link to="/reset_password" className="text-muted">
                        <u>Şifrəni unutmusunuz??</u>
                    </Link>
                    }
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 text-center "
                style={{
                  backgroundSize: "cover",
                  backgroundImage: "url('https://threatshieldsecurity.com/wp-content/uploads/2016/07/1.jpg')"
                }}
              >
                <div className="pr-3 auth-right">
                 {
                    !fetching 
                    && 
                    <a
                      href="/registration"
                      className="btn btn-rounded btn-outline-primary btn-outline-email btn-block btn-icon-text"
                      style={{marginTop:"28px",background:"border-box",color:"#fff"}}
                    >
                    <i className="i-Mail-with-At-Sign"></i> İstifadəçi Qeydiyyatı
                  </a>
                 }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ UserReducer }) => {
  return{
    UserReducer
  }
}

const mapDispatchToProps = {
  loginUser
}

export default connect(mapStateToProps,mapDispatchToProps)(Signin);
