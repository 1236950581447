import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCompletedTasks,getTaskDetailInfo,getTaskByTaskNumberForCompletedTasks } from '../redux/actions/TaskActions';
import CompletedTasksTable from '../components/taskmanager/CompletedTasksTable';
import Loading from '../components/notificationComponents/Loading';

export class CompletedTasks extends Component {

    componentDidMount(){
        let page = 1;
        this.props.getAllCompletedTasks(page);
    }

    render() {
        let { allCompletedTasks,taskDetailIsFetched,taskDetailInfo,fetching,errorMessage,searchResult,pagination } = this.props.TaskReducer;

        return (
            <div>
                
                {
                     fetching === true
                     ?
                     <Loading styleLoading={"left:45%"}/>
                     :
                     <CompletedTasksTable 
                        allCompletedTasks = {allCompletedTasks} 
                        getTaskDetailInfo = {this.props.getTaskDetailInfo}
                        getTaskByTaskNumberForCompletedTasks = {this.props.getTaskByTaskNumberForCompletedTasks}
                        errorMessage = {errorMessage}
                        searchResult = {searchResult}
                        pagination={pagination}
                        taskDetailIsFetched={taskDetailIsFetched}
                        taskDetailInfo={taskDetailInfo}
                     />
                }
                
            </div>
        )
    }
}

const mapStateToProps = ({ TaskReducer }) =>{
    return{
        TaskReducer
    }
}

const mapDispatchToProps = {
    getAllCompletedTasks,
    getTaskDetailInfo,
    getTaskByTaskNumberForCompletedTasks
}

export default connect(mapStateToProps,mapDispatchToProps)(CompletedTasks);
