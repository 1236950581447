import thunk from "redux-thunk";
import logger from 'redux-logger';
import reduxPromise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware} from "redux";
import RootReducer from "./reducers/RootReducer";

const initialState = {};

let devtools = x => x;

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

export const Store = createStore(
  RootReducer,
  initialState,
	composeWithDevTools(
		applyMiddleware(reduxPromise, thunk, logger)
	)
);
