import 
{ 
    FETCH_COMPANY_PENDING,
    FETCH_COMPANY_FULFILLED,
    FETCH_COMPANY_REJECTED,
    CREATE_USER_PENDING,
    CREATE_USER_FULFILLED,
    CREATE_USER_REJECTED,
    LOGIN_USER_PENDING,
    LOGIN_USER_FULFILLED,
    LOGIN_USER_REJECTED,
    GET_USER_PENDING,
    GET_USER_FULFILLED,
    GET_USER_REJECTED,
    GET_USER_ROLE_PENDING,
    GET_USER_ROLE_FULFILLED,
    GET_USER_ROLE_REJECTED,
    GET_USER_ROLE_FOR_CARDSROUTING_PENDING,
    GET_USER_ROLE_FOR_CARDSROUTING_FULFILLED,
    GET_USER_ROLE_FOR_CARDSROUTING_REJECTED,
    GET_NEW_USERS_PENDING,
    GET_NEW_USERS_FULFILLED,
    GET_NEW_USERS_REJECTED,
    ASSIGN_ROLE_TO_USER_PENDING,
    ASSIGN_ROLE_TO_USER_FULFILLED,
    GET_APPROVED_USERS_FULFILLED,
    GET_APPROVED_USERS_REJECTED,
    GET_APPROVED_USERS_PENDING,
    LOCK_USER_PENDING,
    LOCK_USER_FULFILLED,
    LOCK_USER_REJECTED,
    SEARCHING_USER_PENDING,
    SEARCHING_USER_FULFILLED,
    SEARCHING_USER_REJECTED,
    GET_USER_ROLE_FOR_APP_ROUTING_PENDING,
    GET_USER_ROLE_FOR_APP_ROUTING_FULFILLED,
    SEND_RESTORE_PASSWORD_CODE_PENDING,
    SEND_RESTORE_PASSWORD_CODE_FULFILLED,
    RESTORE_USER_PASSWORD_PENDING,
    RESTORE_USER_PASSWORD_FULFILLED,
    RESTORE_USER_PASSWORD_REJECTED
} 
from '../actions/UserActions';

const initialState = {
    companies: [],
    fetching: false,
    userEmailSendFetching: false,
    authorizationFetching: false,
    isSuccessful: false,
    loginIsSuccessful: false,
    appRoles: [],
    modelStateErrors: [],
    restorePasswordError: "",
    token: "",
    userRole: "",
    users:[],
    cardsRoutinUserRole:"",
    isAuthorized: false,
    model: {},
    error: {},
    userAhthorizeError:{},
    operationIsSuccessful: false,
    pagination: null,
    searchResult: null,
    appRoutingUserRole: "",
    isEmailSended: false,
    isSuccessfulRestorePassword: false,
    restoreUserPasswordError: ""
}

export default (state=initialState,action)=>{
    switch(action.type){
        
        //getting companies for user registration
        case FETCH_COMPANY_PENDING:
            return{
                ...state,
                fetching: true
            }
        case FETCH_COMPANY_FULFILLED:
            return{
                ...state,
                fetching: false,
                companies: action.payload
            }
        case FETCH_COMPANY_REJECTED:
            return{
                ...state,
                error: action.payload
            }

        //creating user
        case CREATE_USER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case CREATE_USER_FULFILLED:
            return{
                ...state,
                fetching: false,
                isSuccessful: action.payload.isSuccessfull, 
                modelStateErrors: action.payload.errors.length !== 0 ?  action.payload.errors : []
            }
        case CREATE_USER_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }

        //login user
        case LOGIN_USER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case LOGIN_USER_FULFILLED:
            return{
                ...state,
                fetching: false,
                isAuthorized: action.payload.isAuthorized,
                token: action.payload.token,
                modelStateErrors: action.payload.errors.length !== 0 ?  action.payload.errors : []
            }
        case LOGIN_USER_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }
        
        //get user info
        case GET_USER_PENDING:
            return{
                ...state,
                fetching: true,
            }
        case GET_USER_FULFILLED:
            return{
                ...state,
                fetching: false,
                model: action.payload && action.payload.model
            }
        case GET_USER_REJECTED:
            return{
                ...state,
                fetching: false,
                error: action.payload
            }
        
        //get user role and check is expired token
        case GET_USER_ROLE_PENDING:
            return{
                ...state,
                authorizationFetching: true
            }
        case GET_USER_ROLE_FULFILLED:
            return{
                ...state,
                authorizationFetching: false,
                userRole: action.payload.userRole,
                isAuthorized: action.payload.isAuthorized
            }
        case GET_USER_ROLE_REJECTED:
            return{
                ...state,
                authorizationFetching: false,
                userAhthorizeError: action.payload
            }

        //get user role for cards routing
        case GET_USER_ROLE_FOR_CARDSROUTING_PENDING:
            return{
                ...state,
            }
        case GET_USER_ROLE_FOR_CARDSROUTING_FULFILLED:
            return{
                ...state,
                cardsRoutinUserRole: action.payload.userRole
            }
        case GET_USER_ROLE_FOR_CARDSROUTING_REJECTED:
            return{
                ...state,
                error: action.payload
            }

        //get user role for app routing
        case GET_USER_ROLE_FOR_APP_ROUTING_PENDING:
            return{
                ...state,
            }
        case GET_USER_ROLE_FOR_APP_ROUTING_FULFILLED:
            return{
                ...state,
                appRoutingUserRole: action.payload.userRole
            }

        //get new users
        case GET_NEW_USERS_PENDING:
            return{
                ...state,
                fetching: true
            }
        case GET_NEW_USERS_FULFILLED:
            return{
                ...state,
                users: action.payload && action.payload.model,
                appRoles: action.payload && action.payload.additionalObject,
                pagination: action.payload && action.payload.paginationDto,
                searchResult: null,
                fetching: false
            }
        case GET_NEW_USERS_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }
        
        //get approved users
        case GET_APPROVED_USERS_PENDING:
            return{
                ...state,
                fetching: true
            }
        case GET_APPROVED_USERS_FULFILLED:
            return{
                ...state,
                users: action.payload && action.payload.model,
                appRoles: action.payload && action.payload.additionalObject,
                pagination: action.payload && action.payload.paginationDto,
                searchResult: null,
                fetching: false
            }
        case GET_APPROVED_USERS_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }

        //searching user
        case SEARCHING_USER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case SEARCHING_USER_FULFILLED:
            return{
                ...state,
                users: action.payload && action.payload.model,
                appRoles: action.payload && action.payload.additionalObject,
                searchResult: true,
                fetching: false
            }
        case SEARCHING_USER_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }

        //assign role to user
        case ASSIGN_ROLE_TO_USER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case ASSIGN_ROLE_TO_USER_FULFILLED:
            return{
                ...state,
                operationIsSuccessful: action.payload && action.payload.additionalObject,
                fetching: false
            }
        case ASSIGN_ROLE_TO_USER_FULFILLED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }

        //lock user
        case LOCK_USER_PENDING:
            return{
                ...state,
                fetching: true
            }
        case LOCK_USER_FULFILLED:
            return{
                ...state,
                operationIsSuccessful: action.payload && action.payload.additionalObject,
                fetching: false
            }
        case LOCK_USER_REJECTED:
            return{
                ...state,
                error: action.payload
            }

        //send email to user for restore password
        case SEND_RESTORE_PASSWORD_CODE_PENDING:
            return{
                ...state,
                userEmailSendFetching: true
            }
        case SEND_RESTORE_PASSWORD_CODE_FULFILLED:
            return{
                ...state,
                isEmailSended: action.payload.isSuccessfull,
                restorePasswordError: action.payload.error,
                userEmailSendFetching: false
            }
        
        //restore user password
        case RESTORE_USER_PASSWORD_PENDING:
            return{
                ...state,
                fetching: true
            }
        case RESTORE_USER_PASSWORD_FULFILLED:
            return{
                ...state,
                isSuccessfulRestorePassword: action.payload && action.payload.model,
                restoreUserPasswordError: action.payload.error,
                fetching: false
            }
        case RESTORE_USER_PASSWORD_REJECTED:
            return{
                ...state,
                error: action.payload,
                fetching: false
            }
        default:
            return{
                ...state
            }
    }
}