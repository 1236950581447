import React, { Component } from 'react';
import ModalBody from './ModalBody';

export class TaskDetail extends Component {

    render() {
        return (
            <div>
                <ModalBody 
                    size="lg" 
                    name="Launch large modal"
                    taskDetailInfo = {this.props.taskDetailInfo}
                    saveTaskMessage={this.props.saveTaskMessage}
                    saveTaskMessageFile = {this.props.saveTaskMessageFile}
                    removeTaskMessage={this.props.removeTaskMessage}
                    >
                </ModalBody>
            </div>
        )
    }
}

export default TaskDetail;
