import React from 'react';
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

const Loading = ({ styleLoading }) =>{
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  top: 40%;
  z-index: 99;
  ${styleLoading}
`;
    return(
        <GridLoader
          css={override}
          size={30}
          color={"#1102BB"}
          loading={true}
        />
    );
};

export default Loading;