import React, { Component, Suspense } from "react";
import Layout1Sidenav from "./Layout1Sidenav";
import Layout1Header from "./Layout1Header";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setLayoutSettings } from "app/redux/actions/LayoutActions";
import { merge } from "lodash";

class Layout1 extends Component {
  state = {};

  handleSearchBoxClose = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layout1Settings: {
          searchBox: {
            open: false
          }
        }
      })
    );
  };

  render() {
    let { settings } = this.props;

    return (
      <div>
        <div className={`app-admin-wrap layout-sidebar-large`}>
          <Layout1Header></Layout1Header>
          <Layout1Sidenav />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  setLayoutSettings: PropTypes.func.isRequired,
  settings: state.layout.settings
});

export default connect(mapStateToProps, { setLayoutSettings })(Layout1);
