import { API_BASE } from '../../config/env';
import axios from 'axios';
import { getRequestHeaderWithToken,removeUserInfoFromLocalStorage } from '../../core/localStorage';

export const FETCH_TASK_DROPDOWNS_PENDING = "FETCH_TASK_DROPDOWNS_PENDING";
export const FETCH_TASK_DROPDOWNS_FULFILLED = "FETCH_TASK_DROPDOWNS_FULFILLED";
export const FETCH_TASK_DROPDOWNS_REJECTED = "FETCH_TASK_DROPDOWNS_REJECTED";

export const ADD_TASK_PENDING = "ADD_TASK_PENDING";
export const ADD_TASK_FULFILLED = "ADD_TASK_FULFILLED";
export const ADD_TASK_REJECTED = "ADD_TASK_REJECTED";

export const ADD_ACCEPTED_TASK_PENDING = "ADD_ACCEPTED_TASK_PENDING";
export const ADD_ACCEPTED_TASK_FULFILLED = "ADD_ACCEPTED_TASK_FULFILLED";
export const ADD_ACCEPTED_TASK_REJECTED = "ADD_ACCEPTED_TASK_REJECTED";

export const ASSIGN_TASK_TO_USER_PENDING = "ASSIGN_TASK_TO_USER_PENDING";
export const ASSIGN_TASK_TO_USER_FULFILLED = "ASSIGN_TASK_TO_USER_FULFILLED";
export const ASSIGN_TASK_TO_USER_REJECTED = "ASSIGN_TASK_TO_USER_REJECTED";

export const GET_TASK_STATUSES_PENDING = "GET_TASK_STATUSES_PENDING";
export const GET_TASK_STATUSES_FULFILLED = "GET_TASK_STATUSES_FULFILLED";
export const GET_TASK_STATUSES_REJECTED = "GET_TASK__STATUSES_REJECTED";

export const GET_NOT_ACCEPTED_TASKS_PENDING = "GET_NOT_ACCEPTED_TASKS_PENDING";
export const GET_NOT_ACCEPTED_TASKS_FULFILLED = "GET_NOT_ACCEPTED_TASKS_FULFILLED";
export const GET_NOT_ACCEPTED_TASKS_REJECTED = "GET_NOT_ACCEPTED_TASKS_REJECTED";

export const GET_TASK_DETAIL_PENDING = "GET_TASK_DETAIL_PENDING";
export const GET_TASK_DETAIL_FULFILLED = "GET_TASK_DETAIL_FULFILLED";
export const GET_TASK_DETAIL_REJECTED = "GET_TASK_DETAIL_REJECTED";

export const SEND_TASK_MESSAGE_PENDING = "SEND_TASK_MESSAGE_PENDING";
export const SEND_TASK_MESSAGE_FULFILLED = "SEND_TASK_MESSAGE_FULFILLED";
export const SEND_TASK_MESSAGE_REJECTED = "SEND_TASK_MESSAGE_REJECTED";

export const SEND_TASK_MESSAGE_FILE_PENDING = "SEND_TASK_MESSAGE_FILE_PENDING";
export const SEND_TASK_MESSAGE_FILE_FULFILLED = "SEND_TASK_MESSAGE_FILE_FULFILLED";
export const SEND_TASK_MESSAGE_FILE_REJECTED = "SEND_TASK_MESSAGE_FILE_REJECTED";

export const GET_ALL_COMPLETED_TASKS_PENDING = "GET_ALL_COMPLETED_TASKS_PENDING";
export const GET_ALL_COMPLETED_TASKS_FULFILLED = "GET_ALL_COMPLETED_TASKS_FULFILLED";
export const GET_ALL_COMPLETED_TASKS_REJECTED = "GET_ALL_COMPLETED_TASKS_REJECTED";

export const GET_TASK_BY_TASKNUMBER_PENDING = "GET_TASK_BY_TASKNUMBER_PENDING";
export const GET_TASK_BY_TASKNUMBER_FULFILLED = "GET_TASK_BY_TASKNUMBER_FULFILLED";
export const GET_TASK_BY_TASKNUMBER_REJECTED = "GET_TASK_BY_TASKNUMBER_REJECTED";

export const GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_PENDING = "GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_PENDING";
export const GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_FULFILLED = "GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_FULFILLED";
export const GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_REJECTED = "GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS_REJECTED";

export const MAIN_SEARCH_TASK_PENDING = "MAIN_SEARCH_TASK_PENDING";
export const MAIN_SEARCH_TASK_FULFILLED = "MAIN_SEARCH_TASK_FULFILLED";
export const MAIN_SEARCH_TASK_REJECTED = "MAIN_SEARCH_TASK_REJECTED";

export const REMOVE_TASK_MESSAGE_PENDING = "REMOVE_TASK_MESSAGE_PENDING";
export const REMOVE_TASK_MESSAGE_FULFILLED = "REMOVE_TASK_MESSAGE_FULFILLED";
export const REMOVE_TASK_MESSAGE_REJECTED = "REMOVE_TASK_MESSAGE_REJECTED";


export function fetchTaskDropdownItems(){
    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "FETCH_TASK_DROPDOWNS",
            payload: axios.get(`${API_BASE}/TaskActions/GetTaskDropdownItems`,{headers})
            .then(model=>model.data.model)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        });
    };
};

export function addTask(model){

    let headers =  getRequestHeaderWithToken();
    return dispatch =>{
        dispatch({
            type: "ADD_TASK",
            payload: axios.post(`${API_BASE}/TaskActions/AddTask`,model,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                
            })
        })
    }
}

export function getUserNotAcceptedTasks(id,page){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "GET_NOT_ACCEPTED_TASKS",
            payload: axios.get(`${API_BASE}/TaskActions/GetUserNotAcceptedTasks/${id}/${page}`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function getTaskStatuses(){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "GET_TASK_STATUSES",
            payload: axios.get(`${API_BASE}/TaskActions/GetTaskStatuses`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function addAcceptedTask(model){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "ADD_ACCEPTED_TASK",
            payload: axios.post(`${API_BASE}/TaskActions/SaveAcceptedTask`,model,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function assignTaskToUser(model){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "ASSIGN_TASK_TO_USER",
            payload: axios.post(`${API_BASE}/TaskActions/AssignTaskToUser`,{
                applicationTaskId: model.applicationTaskId,
                userId: model.userId
            },{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function getTaskDetailInfo(taskId){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "GET_TASK_DETAIL",
            payload: axios.get(`${API_BASE}/TaskActions/GetTaskDetailInfo/${taskId}`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
               
            })
        })
    }
}

export function saveTaskMessage(model){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "SEND_TASK_MESSAGE",
            payload: axios.post(`${API_BASE}/TaskActions/AddTaskMessage`,model,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function getAllCompletedTasks(page){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "GET_ALL_COMPLETED_TASKS",
            payload: axios.get(`${API_BASE}/TaskActions/GetAllCompletedTasks/${page}`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function getTaskByTaskNumber(taskNumberPattern){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "GET_TASK_BY_TASKNUMBER",
            payload: axios.get(`${API_BASE}/TaskActions/GetTaskByTaskNumber/${taskNumberPattern}`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function getTaskByTaskNumberForCompletedTasks(taskNumberPattern){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "GET_TASK_BY_TASKNUMBER_COMPLETED_TASKS",
            payload: axios.get(`${API_BASE}/TaskActions/GetTaskByTaskNumberForCompletedTasks/${taskNumberPattern}`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function saveTaskMessageFile(model){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "SEND_TASK_MESSAGE_FILE",
            payload: axios.post(`${API_BASE}/TaskActions/AddTaskMessageFile`,model,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
            })
        })
    }
}

export function mainSearchTask(pattern){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "MAIN_SEARCH_TASK",
            payload: axios.get(`${API_BASE}/SearchingActions/MainSearch/${pattern}`,{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
                else{
                    window.location = "/TaskManagement/404";
                }
            })
        })
    }
}

export function removeTaskMessage(messageId){
    let headers =  getRequestHeaderWithToken();
    return dispatch=>{
        dispatch({
            type: "REMOVE_TASK_MESSAGE",
            payload: axios.post(`${API_BASE}/TaskActions/RemoveTaskMessage/`,{id:messageId},{headers})
            .then(model =>model.data)
            .catch(function(error){
                if (error.response && error.response.status === 401) {
                    removeUserInfoFromLocalStorage()
                    window.location = "/TaskManagement";
                }
            })
        })
    }
}


