import React, { Component } from 'react';
import { connect } from 'react-redux';
import TaskList from '../components/taskmanager/TaskList';
import Loading from '../components/notificationComponents/Loading';
import 
{ 
    getTaskStatuses,
    getUserNotAcceptedTasks,
    addAcceptedTask,
    assignTaskToUser,
    getTaskDetailInfo,
    saveTaskMessage,
    getTaskByTaskNumber,
    saveTaskMessageFile,
    removeTaskMessage 
} 
from '../redux/actions/TaskActions';

export class Tasks extends Component {

    state = {
        notAcceptedTaskId: 1
    }

    componentDidMount(){

        this.props.getTaskStatuses();

        let taskId = this.props.match.params.taskId;
        if(taskId !== "" && taskId !== undefined && taskId !== null){
            this.props.getTaskByTaskNumber(taskId)
        }
        else{
            if(this.props.TaskReducer.applicationTasks.length === 0){
                let page =1;
                this.props.getUserNotAcceptedTasks(this.state.notAcceptedTaskId,page);
            }
        }
    }

    render() {
        let { modelTaskStatuses,fetching,applicationTasks,isTaskStatusSuccessfulChanged,taskStatusId,pagination,searchResult,isMainSearch } = this.props.TaskReducer;
        return (
            <div>
                {
                    fetching === true
                    ?
                    <Loading styleLoading={"left:45%"}/>
                    :                    
                    <TaskList 
                        taskStatuses = {modelTaskStatuses}
                        applicationTasks = { applicationTasks }
                        getUserNotAcceptedTasks={this.props.getUserNotAcceptedTasks}
                        addAcceptedTask = {this.props.addAcceptedTask}
                        isTaskStatusSuccessfulChanged = { isTaskStatusSuccessfulChanged }
                        taskStatusId = { taskStatusId }
                        assignTaskToUser= { this.props.assignTaskToUser }
                        getTaskDetailInfo={this.props.getTaskDetailInfo}
                        taskDetailIsFetched={this.props.TaskReducer.taskDetailIsFetched}
                        taskDetailInfo={this.props.TaskReducer.taskDetailInfo}
                        saveTaskMessage = {this.props.saveTaskMessage}
                        isTaskAssignSuccessful = {this.props.isTaskAssignSuccessful}
                        pagination={pagination}
                        getTaskByTaskNumber = {this.props.getTaskByTaskNumber}
                        searchResult={searchResult}
                        saveTaskMessageFile = {this.props.saveTaskMessageFile}
                        isMainSearch={isMainSearch}
                        removeTaskMessage={this.props.removeTaskMessage}
                    />
                   
                }
            </div>
        )
    }
}

const mapStateToProps = ({ TaskReducer }) => {
    return{
        TaskReducer
    }
}

const mapDispatchToProps = {
    getTaskStatuses,
    getUserNotAcceptedTasks,
    addAcceptedTask,
    assignTaskToUser,
    getTaskDetailInfo,
    saveTaskMessage,
    getTaskByTaskNumber,
    saveTaskMessageFile,
    removeTaskMessage
}

export default connect(mapStateToProps,mapDispatchToProps)(Tasks);
